<template>
  <div class="wrapper">
    <h2 class="highlight item-hidden-right h2">Our Routes</h2>
    <h1 class="h1">Discover the Best Travel Routes with Us</h1>
    <p class="p">
      We are dedicated to providing exceptional travel routes that cater to your
      journey needs:
    </p>
    <!--   ****   LOADER   ****    -->
    <div v-if="isLoading" class="vl-parent">
      <loading
        v-model:active="isLoading"
        :can-cancel="false"
        loader="spinner"
        background-color="#000"
        color="#fff"
        :opacity="0.3"
        :width="34"
        :height="34"
        :is-full-page="false"
      />
    </div>
    <div v-if="!isLoading && journeyList.length > 0" class="routes">
      <div
        v-for="(journey, index) in journeyList"
        :key="index"
        class="route-item gap-2 cursor-pointer"
        @click="goToSelectSeats(journey)"
      >
        <img :src="logoImg" alt="Logo" class="app-logo" />
        <div class="flex flex-col items-start justify-center">
          <h3 class="h3 m-0 text-[1rem]">
            {{
              journey.trip_route.start_point +
              " - " +
              journey.trip_route.end_point
            }}
          </h3>
          <div class="text-gray-400 text-sm sub-title">
            {{ journey.trip_date + "." }}
            <span class="text-[10px]">
              {{ twelveHourFormat(journey.departing_time) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="primary-button text-[10px] md:text-[14px]">Show More</button> -->
  </div>
</template>

<script>
import sessionManager from "../../../../core/utils/sessionManager.js";

import logoImgSrc from "@/assets/images/Mumuk-Logo.png";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { useToast } from "vue-toastification";
export default {
  props: {
    journeyList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      from: 1,
      to: 2,
      date: "2024-09-04",
      fromName: "DAR ES SALAAM",
      toName: "MBEYA",
      isLoading: false,
      message: "",
      logoImg: logoImgSrc,
    };
  },
  components: {
    Loading,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    goToSelectSeats(bus) {
      sessionManager.storeTripDetails(
        bus.trip_route.start_point,
        bus.trip_route.end_point,
        bus.trip_route.start_point_id,
        bus.trip_route.end_point_id,
        bus.trip_date
      );

      sessionManager.storeBusDetails(bus);
      this.$router.push({
        path: "/selectSeat",
        query: { id: bus.id },
      });
    },
    navigateToJourney() {
      sessionManager.storeTripDetails(
        this.fromName,
        this.toName,
        this.from,
        this.to,
        this.date
      );

      this.$router.push({
        path: "/journey",
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #f7f7f7;
}

.routes {
  margin-left: 140px;
  margin-right: 140px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.route-item {
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.route-item:hover {
  /* background-color: #22b247; */
  box-shadow: 0 0px 0px rgba(255, 255, 255, 0);
  outline: solid #22b247 2px;
  outline-offset: -2px;
}

@media (max-width: 955px) {
  .routes {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 768px) {
  .routes {
    margin-left: 20px;
    margin-right: 20px;
  }

  .route-item {
    width: calc(50% - 40px);
  }
}

@media (max-width: 576px) {
  .routes {
    margin-left: 0;
    margin-right: 0;
  }

  .route-item {
    width: 100%;
  }
}
</style>
