<template>
  <div
    class="top-nav-bar fixed top-0 left-0 w-full flex flex-col justify-between items-center bg-white text-white z-50 shadow-md"
  >
    <div class="flex flex-row w-full justify-between">
      <div class="nav-bar-logo flex flex-row items-center">
        <!-- <div class="text-theme text-[28px] font-bold">mysafari</div> -->
        <img :src="logoImg" alt="Logo" class="app-logo h-[70px]" />
      </div>
      <button class="nav__toggle">
        <span class="hamburger"></span>
      </button>
    </div>
    <ul class="top-nav-bar-elements text-gray-700 text-center">
      <li @click="onClickHome" class="top-nav-bar-element">Home</li>
      <li @click="onClickAboutUs" class="top-nav-bar-element">About Us</li>
      <li @click="onClickServices" class="top-nav-bar-element">Services</li>
      <li @click="onClickOurBus" class="top-nav-bar-element">Our Bus</li>
      <li @click="onClickGallery" class="top-nav-bar-element">Gallery</li>
      <li @click="onClickHelpCenter" class="top-nav-bar-element">
        Help Center
      </li>
    </ul>
  </div>
</template>

<script>
import logoImgSrc from "@/assets/images/Mumuk-Logo.png";

export default {
  data() {
    return { logoImg: logoImgSrc };
  },
  props: {
    onClickHome: {
      type: Function,
      required: true,
    },
    onClickAboutUs: {
      type: Function,
      required: true,
    },
    onClickServices: {
      type: Function,
      required: true,
    },
    onClickOurBus: {
      type: Function,
      required: true,
    },
    onClickGallery: {
      type: Function,
      required: true,
    },
    onClickHelpCenter: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.top-nav-bar-elements {
  margin-top: 10px;
  transition: all 400ms ease-in-out;
  opacity: 0;
}

.top-nav-bar {
  height: 70px;
  overflow-y: hidden;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 400ms ease-in-out;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
}

.top-nav-bar.open {
  height: 330px;
  border-radius: 0 0 16px 16px;
}

.top-nav-bar-elements.show {
  display: block;
  opacity: 1;
}
.top-nav-bar-element {
  margin-bottom: 20px;
  cursor: pointer;
}

@media (min-width: 767px) {
  .top-nav-bar {
    display: none;
  }
}
</style>
