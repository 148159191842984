<template>
  <div class="wrapper">
    <h2 class="highlight item-hidden-bottom h2">Our Services</h2>
    <h1 class="h1">Best Services For You</h1>
    <p class="p">
      At Mumuk Investment, we offer premium services to make your travel
      <br />as comfortable and secure as possible. Whether it’s a quick trip
      between cities or a <br />long-distance adventure, Mumuk ensures you
      arrive safely and on time.
    </p>

    <div class="services-container">
      <div class="services">
        <div class="service-item item-hidden-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-shield-check"
            viewBox="0 0 16 16"
          >
            <path
              d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"
            />
            <path
              d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0"
            />
          </svg>
          <h3 class="h3">Safety Guarantee</h3>
          <p class="p">
            Your safety is our priority. All our buses are fully insured and
            maintained to the highest standards.
          </p>
          <!-- <div class="read-more">
            Read More <span><i class="bi bi-arrow-right"></i></span>
          </div> -->
        </div>

        <div class="service-item item-hidden-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-tags"
            viewBox="0 0 16 16"
          >
            <path
              d="M3 2v4.586l7 7L14.586 9l-7-7zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586z"
            />
            <path
              d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1z"
            />
          </svg>
          <h3 class="h3">Discount & Promo</h3>
          <p class="p">
            Enjoy the best deals and save money on your bus tickets with our
            exclusive promotions.
          </p>
          <!-- <div class="read-more">
            Read More <span><i class="bi bi-arrow-right"></i></span>
          </div> -->
        </div>

        <div class="service-item item-hidden-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-patch-check"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"
            />
            <path
              d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"
            />
          </svg>
          <h3 class="h3">Professional Staff</h3>
          <p class="p">
            Our experienced and professional staff are here to ensure you have a
            smooth travel experience.
          </p>
          <!-- <div class="read-more">
            Read More <span><i class="bi bi-arrow-right"></i></span>
          </div> -->
        </div>
      </div>

      <div class="services">
        <div class="service-item item-hidden-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-stopwatch"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z"
            />
            <path
              d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3"
            />
          </svg>
          <h3 class="h3">Punctual Scheduling:</h3>
          <p class="p">
            We value your time, which is why our buses are scheduled to depart
            and arrive on time.
          </p>
          <!-- <div class="read-more">
            Read More <span><i class="bi bi-arrow-right"></i></span>
          </div> -->
        </div>

        <div class="service-item item-hidden-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-phone"
            viewBox="0 0 16 16"
          >
            <path
              d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
            />
            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
          </svg>
          <h3 class="h3">Online Booking</h3>
          <p class="p">Easily book your tickets online, anytime, anywhere.</p>
          <!-- <div class="read-more">
            Read More <span><i class="bi bi-arrow-right"></i></span>
          </div> -->
        </div>

        <div class="service-item item-hidden-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-moon-stars"
            viewBox="0 0 16 16"
          >
            <path
              d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278M4.858 1.311A7.27 7.27 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.32 7.32 0 0 0 5.205-2.162q-.506.063-1.029.063c-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286"
            />
            <path
              d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
            />
          </svg>
          <h3 class="h3">24/7 Support</h3>
          <p class="p">
            Our customer support team is available around the clock to assist
            with any inquiries or issues.
          </p>
          <!-- <div class="read-more">
            Read More <span><i class="bi bi-arrow-right"></i></span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrapper {
  background-color: #f7f7f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  padding-top: 60px;
}

.services-container {
  padding-top: 80px;
}

.services {
  /* transform: translateY(80px); */
  margin-left: 160px;
  margin-right: 160px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.service-item {
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 60px 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.13);
  flex: 1 1 calc(33.333% - 40px); /* 3 columns with 20px gap on each side */

  background-image: linear-gradient(to top, #22b247 50%, transparent 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: all 0.6s ease-in-out;
}

.service-item:hover {
  background-position: bottom;
}

.services .service-item:nth-child(2) {
  transform: translateY(-80px);
}

.service-item svg {
  transition: all 0.6s ease-in-out;
}

.service-item:hover svg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 6px;
  fill: #d80322;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.13);
}

.service-item:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.service-item:hover h3 {
  color: rgba(255, 255, 255, 1);
}

.service-item:hover .read-more {
  color: white; /* Change the Read More text color on hover */
}

.service-item svg {
  width: 40px;
  color: #d80322;
  margin-bottom: 20px;
}
</style>
