<template>
  <div
    class="top-header-info fixed top-0 left-0 w-full flex flex-row justify-between items-center p-2 bg-black text-white z-50 shadow-md"
  >
    <!-- <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 5H20C18.9543 1.90034 16.3118 0 13 0H0V3H13C14.6051 3 16.0582 3.79585 17 5H0V14H5C5.23974 14 5.79794 13.8695 6 14L10 16C10.6233 16.3972 11.2602 17 12 17H20V21H18C17.1815 19.4502 15.8642 18 14 18C12.1369 18 10.8174 19.4502 10 21H0V23H9C9.16211 25.5399 11.4223 28 14 28C16.5789 28 18.8379 25.5399 19 23H21C21.7146 23 22.9932 22.7234 23 22V16C23 15.8958 23.0263 16.1042 23 16L21 8H22C22.6039 8 23 8.39449 23 9V11H26V9C26 6.95569 24.0389 5 22 5Z"
        fill="#22B247"
      />
    </svg> -->

    <div class="info-item flex flex-row gap-2 items-center">
      <i class="bi bi-geo-alt-fill text-theme"></i>
      <div class="text-[12px] text-gray-300">Arusha Stand, Arusha Tanzania</div>
    </div>

    <div class="left">
      <div class="flex flex-row gap-3 items-center icons">
        <!-- <a
          href="https://www.facebook.com/profile.php?id=61563272180262"
          class="text-white p-[4px] rounded-md cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-facebook"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
            />
          </svg>
        </a>
        <a
          href="https://x.com/mysafari255"
          class="text-white p-[4px] rounded-md cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-twitter-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
            />
          </svg>
        </a> -->
        <a
          href="https://www.instagram.com/mumuk_investement_co_ltd/"
          class="text-white p-[4px] rounded-md cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-instagram"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
            />
          </svg>
        </a>
        <!-- <a
          href="https://www.tiktok.com/@mysafaritanzania"
          class="text-white p-[4px] rounded-md cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-tiktok"
            viewBox="0 0 16 16"
          >
            <path
              d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"
            />
          </svg>
        </a>
        <a
          href="https://www.threads.net/@mysafaritanzania?hl=en"
          class="text-white p-[4px] rounded-md cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-threads"
            viewBox="0 0 16 16"
          >
            <path
              d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161"
            />
          </svg>
        </a> -->
        <div class="h-4 w-[1.2px] bg-gray-400"></div>
        <div
          class="flex flex-row items-center gap-2 cursor-pointer"
          @click="openWhatsApp"
        >
          <i class="bi bi-whatsapp text-green-600"></i>
          <div class="text-[11px] text-gray-300">+255 753 876 198</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWhatsApp() {
      const phone = encodeURIComponent("+255753876198");
      const message = "";
      const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(
        message
      )}`;

      window.open(whatsappUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.icons a {
  transition: transform 0.4s ease, background-color 0.4s ease;
}

.icons a:hover {
  background-color: #a43e1a;
}

@media (max-width: 767px) {
  .top-header-info {
    display: none;
  }
}
</style>
