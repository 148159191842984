import { ref } from "vue";
import axiosInstance from "../config/axiosConfig.js";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

class RegionService {
  constructor() {
    //     this.authorizeActionData = ref(false);
    this.message = ref("");
    this.regionsData = ref([]);

    this.toast = useToast();
    this.router = useRouter();
  }

  async getRegions() {
    try {
      const response = await axiosInstance.get(`/passenger/regions`);
      this.regionsData.value = response.data.regions;
      return true;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        // this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while getting Passenger Regions:", e);
      }
      return false;
    }
  }
}

export default RegionService;
