<template>
  <!-- BUS IMAGES Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{
              selectedBus
                ? selectedBus.vehicle.company +
                  " - " +
                  selectedBus.vehicle.registration_number
                : "Bus name"
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="selectedBus != null" class="modal-body image-grid">
          <img
            v-for="(image, index) in selectedBus
              ? selectedBus.vehicle.images
              : []"
            :key="index"
            :src="image.image"
            alt="Bus"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="primary-button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- BUS AMENITIES Modal -->
  <div
    class="modal fade"
    id="busAmenitesModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="busAmenitesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="busAmenitesModalLabel">
            {{
              selectedBus
                ? selectedBus.vehicle.company +
                  " - " +
                  selectedBus.vehicle.registration_number +
                  " - AMENITIES"
                : "Bus name"
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="flex flex-col gap-3 m-3">
            <div
              v-if="selectedBus"
              v-for="(amenite, index) in selectedBus.vehicle.amenities"
              :key="index"
            >
              <div
                class="flex flex-row gap-3 bg-white rounded-lg p-2 shadow-sm items-center"
              >
                <img
                  :src="amenite.icon"
                  alt=""
                  srcset=""
                  style="width: 50px; height: 50px"
                  class="bg-gray-200 p-2 rounded-md"
                />
                <div class="flex flex-col">
                  <h3 class="font-bold text-xl text-theme">
                    {{ amenite.name }}
                  </h3>
                  <p class="text-gray-400 text-[12px]">
                    {{ amenite.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="primary-button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <h2 class="highlight item-hidden-right h2">Our Bus</h2>
    <div class="section-header gap-2">
      <h1 class="h1">Our Bus Collection</h1>
      <p class="text-small py-3 md:py-0">
        Explore our range of buses, all fully insured and ready to take you to
        your destination
      </p>
      <!-- <button class="primary-button w-[160px]">All Vehicle</button> -->
    </div>

    <!--   ****   LOADER   ****    -->
    <div v-if="isLoading" class="vl-parent">
      <loading
        v-model:active="isLoading"
        :can-cancel="false"
        loader="spinner"
        background-color="#000"
        color="#fff"
        :opacity="0.3"
        :width="34"
        :height="34"
        :is-full-page="false"
      />
    </div>
    <div v-if="journeyList.length > 0" class="available-buses pt-16">
      <div
        v-for="(bus, index) in journeyList"
        :key="index"
        class="available-bus-item"
      >
        <img
          v-if="bus.vehicle.images.length !== 0"
          :src="bus.vehicle.images[0].image"
          alt="Bus"
          @click="selectedBus = bus"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        />
        <img
          v-else
          :src="busImageSrc"
          alt="Bus"
          @click="toast.info('This bus has no Images.')"
        />
        <div class="available-bus-info">
          <div
            class="route-details flex flex-row justify-between text-gray-400 text-[12px] w-full"
          >
            <div class="from">From</div>
            <div>{{ bus.remained_seats }} Seats remain</div>
            <div class="to">To</div>
          </div>
          <div
            class="route-details grid grid-cols-3 flex-row justify-between items-center text-gray-700 text-[10px] w-full py-2 gap-2"
          >
            <div class="from col-span-1">
              <div class="font-bold text-[12px]">
                {{ bus.trip_route.start_point_code }}
              </div>
              <div>{{ bus.trip_route.start_point }}</div>
              <div>{{ twelveHourFormat(bus.departing_time) }}</div>
            </div>
            <div class="flex flex-col justify-center items-center col-span-1">
              <i
                class="bi bi-truck-front-fill bg-theme text-white px-2 py-1 text-[14px] rounded-[50%]"
              ></i>
              <div>{{ bus.trip_date }}</div>
            </div>
            <div class="to col-span-1 text-right">
              <div class="font-bold text-[12px]">
                {{ bus.trip_route.end_point_code }}
              </div>
              <div>{{ bus.trip_route.end_point }}</div>
              <div>{{ twelveHourFormat(bus.arrival_time) }}</div>
            </div>
          </div>
          <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
          <div
            class="services text-[12px] flex flex-row items-center justify-center gap-2"
          >
            <div>{{ bus.vehicle.fleet_type }}</div>
            <div v-for="(amenite, index) in bus.vehicle.amenities" :key="index">
              <img
                :src="amenite.icon"
                alt=""
                srcset=""
                style="width: 16px; height: 16px; border-radius: 0"
                @click="selectedBus = bus"
                data-bs-toggle="modal"
                data-bs-target="#busAmenitesModal"
              />
            </div>
          </div>
          <button
            class="book-now-btn bg-second text-white font-bold my-3 px-5 py-2 text-[14px] rounded-[20px]"
            @click="goToSelectSeats(bus)"
          >
            Tsh {{ formatNumber(bus.fare) }}/=
          </button>
        </div>
        <div class="bus-name flex flex-row items-center p-2 rounded-md">
          <div>{{ bus.vehicle.company }}</div>
          <div class="w-[0.7px] h-3 bg-gray-300 mx-2"></div>
          <div>{{ bus.vehicle.registration_number }}</div>
        </div>
      </div>

      <!-- <div class="bus-item item-hidden-bottom">
        <img class="rounded-xl" :src="busImageSrc" alt="bus" />
        <div class="bus-info">
          <h3 class="h3">Kimotco Express</h3>
          <div class="highlight font-bold text-xl pt-2">
            TSH54,000<span class="text-[#A1A1A1] text-[16px]">/trip</span>
          </div>
          <div class="h-[1px] w-16 bg-[#A1A1A1] my-3"></div>
          <div class="flex justify-center items-center gap-3 pb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-people-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
              />
            </svg>
            <p class="text-small text-[#A1A1A1]">60 Seat</p>
          </div>
          <div class="flex justify-center items-center gap-3 pb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-gear-wide-connected"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z"
              />
            </svg>
            <p class="text-small text-[#A1A1A1]">Manual</p>
          </div>
          <div class="flex justify-center items-center gap-3 pb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-credit-card-2-front-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"
              />
            </svg>
            <p class="text-small text-[#A1A1A1]">Fully Insured</p>
          </div>
          <div class="flex items-center justify-around gap-3">
            <button class="primary-button text-[10px] md:text-[16px]">
              Book Now
            </button>
            <div class="read-more text-[10px] md:text-[16px]">
              View Details <span><i class="bi bi-arrow-right"></i></span>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div ref="numberElement" class="hidden">
      <p>{{ Math.floor(currentNumber) }}</p>
    </div>
  </div>
</template>

<script>
import bus from "@/assets/images/bus.jpg";
import sessionManager from "../../../../core/utils/sessionManager.js";
import JourneyService from "../../../../core/services/journeyService.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { useToast } from "vue-toastification";

export default {
  props: {
    journeyList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      busImageSrc: bus,
      currentNumber: 0,
      targetNumber: 100,
      incrementSpeed: 20,
      observer: null,
      isInView: false,
      isLoading: false,
      message: "",
      selectedBus: null,
    };
  },
  components: {
    Loading,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.createObserver();
  },
  methods: {
    goToSelectSeats(bus) {
      sessionManager.storeTripDetails(
        bus.trip_route.start_point,
        bus.trip_route.end_point,
        bus.trip_route.start_point_id,
        bus.trip_route.end_point_id,
        bus.trip_date
      );

      sessionManager.storeBusDetails(bus);
      this.$router.push({
        path: "/selectSeat",
        query: { id: bus.id },
      });
    },
    createObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.isInView) {
            this.isInView = true;
            this.startIncrement();
          }
        });
      });

      this.observer.observe(this.$refs.numberElement);
    },
    startIncrement() {
      const step = this.targetNumber / 100; // Number of steps
      const interval = setInterval(() => {
        if (this.currentNumber < this.targetNumber) {
          this.currentNumber += step;
        } else {
          this.currentNumber = this.targetNumber;
          clearInterval(interval);
        }
      }, this.incrementSpeed);
    },
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: center;
  padding: 40px 180px;
}

.section-header {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  align-items: center;
  justify-content: center;
  text-align: start;
}

.available-buses {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  gap: 20px;
  width: 100%;
  /* place-items: center; */
}

.available-bus-item {
  width: 100%;
  position: relative;
  background-color: white;
  border: #e2dedc7e 0.1px solid;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  transition: all 200ms ease-in-out;
}

.bus-name {
  font-size: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.611);
  color: white;
  font-weight: 500;
}

.available-bus-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
}

.available-bus-item:hover {
  transform: translateY(-5px);
  scale: 1.01;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.available-bus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
}

.buses {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 20px;
}

.bus-item {
  position: relative;
  height: 250px;
}

.bus-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  transition: all 300ms ease-in-out;
}

.bus-item img:hover {
  transform: translateY(-10px);
  scale: 1.01;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.bus-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 190px;
  left: 0;
  right: 0;
  background-color: white;
  padding: 40px 20px;
  margin: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bus-info svg {
  color: #22b247;
}

@media (max-width: 1184px) {
  .wrapper {
    padding: 40px 20px;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .journey-date {
    margin-left: 0px;
    margin-right: 0px;
  }
  .available-buses {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .available-buses {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
