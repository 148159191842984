<template>
  <!-- <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      loader="spinner"
      background-color="#000"
      color="#fff"
      :opacity="0.3"
      :width="34"
      :height="34"
      :is-full-page="true"
    />
  </div> -->

  <div class="page-wrapper">
    <!-- ********    TOP-HEADER SECTION    ******** -->
    <TopHeader />
    <!-- ********    END TOP-HEADER SECTION    ******** -->

    <!-- ********    HEADER SECTION    ******** -->
    <div class="nav__toggle hidden"></div>
    <div class="header-section">
      <div class="top-content">
        <div></div>
        <div class="top-content-right">
          <h1 class="h1">
            Your <span class="highlight text-second">Ticket</span>
          </h1>
          <p class="p">
            Secure your ticket with ease. Enjoy a smooth and enjoyable travel
            experience from start to finish.
          </p>
        </div>
      </div>
      <div
        class="journey-date bg-white-opacity text-black text-sm flex flex-row justify-between rounded-lg"
      >
        <div class="flex flex-col items-start p-3">
          <div
            class="flex flex-row gap-2 text-white text-[10px] md:text-[16px]"
          >
            <div>{{ fromName }}</div>
            <i class="bi bi-arrow-right-short text-theme text-[20px]"></i>
            <div>{{ toName }}</div>
          </div>
          <div class="text-gray-400 text-[12px] md:text-[16px]">
            <div>{{ busDetails.trip_date }}</div>
          </div>
        </div>
        <div
          @click="goHome"
          class="date-button flex flex-row justify-center items-center cursor-pointer bg-theme text-[14px] md:text-[16px] rounded-r-lg gap-2 p-3"
        >
          <i class="bi bi-house"></i>
          <button>Home</button>
        </div>
      </div>
    </div>
    <!-- ********    END HEADER SECTION    ******** -->

    <!-- ********    BODY SECTION    ******** -->
    <section class="body-section">
      <div class="bg-second p-4 pt-5 w-full md:w-[500px] md:m-3">
        <h1 class="text-2xl text-white font-bold pb-5">
          Payment {{ !isLoading ? "Completed" : "Pending..." }}
        </h1>

        <div
          v-if="isLoading"
          class="flex flex-col bg-white rounded-xl shadow-2xl items-center justify-center gap-2 px-4 py-5 mb-3"
        >
          <div class="p-0">
            <flower-spinner
              :animation-duration="2000"
              :size="65"
              color="#22B247"
            />
          </div>
          <div class="text-gray-400">
            Processing transaction. Please wait...
          </div>
        </div>

        <div
          v-else
          v-for="(booking, index) in bookings"
          :key="booking.id"
          :ref="'receipt' + index"
        >
          <div class="bg-white p-[6px] rounded-2xl shadow-md">
            <div class="bg-theme h-[10px] w-full rounded-xl"></div>
          </div>
          <div class="receipt-container mx-2 transform translate-y-[-12px]">
            <div
              class="flex flex-col text-black p-4 custom-rounded"
              style="background-color: #ffffff !important"
            >
              <h1 class="text-2xl pb-1">{{ booking.company }}</h1>
              <div class="flex flex-row justify-between items-center mb-3">
                <h1 class="text-2xl font-bold">{{ booking.from_code }}</h1>
                <img :src="logoImg" alt="Logo" class="app-logo h-[90px]" />

                <h1 class="text-2xl font-bold">{{ booking.to_code }}</h1>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">Bus Reg No:</div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.trip.vehicle.registration_number }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Booking Time:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.booking_date }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Journey Date:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.travel_date }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Reporting Time:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ twelveHourFormat(booking.trip.reporting_time) }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Departure Time:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ twelveHourFormat(booking.trip.departing_time) }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Pick-up Point:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.pickup_point }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Drop-off Point:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.drop_off_point }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">Passenger:</div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.passenger.name }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">Phone No:</div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.passenger.phone_number }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">Email:</div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.passenger.email }}
                </div>
              </div>
              <div class="grid grid-cols-5 items-center mb-3">
                <div class="col-span-2 text-gray-400 text-sm">
                  Help Center No:
                </div>
                <div class="col-span-3 break-words font-bold">
                  {{ booking.help_desk_phone_number }}
                </div>
              </div>
              <div class="flex flex-row justify-between mb-3">
                <div class="flex flex-col items-center justify-center">
                  <div class="text-gray-400 text-sm">Age:</div>
                  <div class="break-words font-bold">
                    {{ booking.passenger.age_group }}
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <div class="text-gray-400 text-sm">Seat:</div>
                  <div class="break-words font-bold">
                    {{ booking.seat_number }}
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <div class="text-gray-400 text-sm">Baggage:</div>
                  <div class="break-words font-bold">No</div>
                </div>
              </div>
              <h3 class="text-center text-xl font-bold">
                Total Fare: Tsh {{ booking.fare }}/=
              </h3>
            </div>
            <div
              class="flex flex-col text-black rounded-2xl"
              style="background-color: #ffffff !important"
            >
              <div class="custom-dotted-line"></div>

              <div class="flex flex-col md:flex-row items-center gap-5 p-5">
                <div class="qrcode">
                  <QRCodeVue :value="booking.booking_number" :size="100" />
                </div>
                <div class="flex flex-col gap-1 items-start justify-center">
                  <div class="text-gray-400 text-sm">REF:</div>
                  <div class="break-words font-bold">
                    {{ booking.booking_number }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <div
            v-if="!isLoading"
            @click="downloadPdf"
            class="primary-button w-min mt-2 mx-1 text-theme bg-white"
          >
            Download
          </div>
        </div>
      </div>
    </section>
    <!-- ********    END BODY SECTION    ******** -->

    <!-- ********    FOOTER SECTION    ******** -->
    <section class="footer-section"><Footer /></section>
    <!-- ********    END FOOTER SECTION    ******** -->
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import TopHeader from "../../components/TopHeader.vue";
import { useToast } from "vue-toastification";
import { FlowerSpinner } from "epic-spinners";
import logoImgSrc from "@/assets/images/Mumuk-Logo.png";

import sessionManager from "../../../core/utils/sessionManager.js";
import PaymentService from "../../../core/services/paymentService.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QRCodeVue from "qrcode.vue";

export default {
  data() {
    return {
      fromName: "",
      toName: "",
      tripDate: "",
      journeyData: {},
      journeyId: 0,
      isLoading: false,
      phone_number: "",
      bookings: [],
      intervalId: null,
      paymentStatus: {},
      logoImg: logoImgSrc,
    };
  },
  components: {
    Loading,
    Footer,
    TopHeader,
    PaymentService,
    Loading,
    html2canvas,
    QRCodeVue,
    FlowerSpinner,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.startTimer();
    this.isLoading = true;
    this.intervalId = setInterval(() => {
      this.checkPaymentStatus();
    }, 10000); // 10000ms = 10 seconds
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async downloadPdf() {
      try {
        for (let i = 0; i < this.bookings.length; i++) {
          const receiptElement = this.$refs["receipt" + i][0]; // Access the specific receipt by ref

          if (receiptElement) {
            const canvas = await html2canvas(receiptElement, {
              backgroundColor: "#ffffff", // Explicitly set the background color
            });
            const imgData = canvas.toDataURL("image/png");

            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "px",
              format: [canvas.width, canvas.height],
            });

            pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
            pdf.save(`ticket_${i + 1}.pdf`); // Save each PDF with a unique name
          }
        }
      } catch (error) {
        console.error("Failed to generate PDF:", error);
      }
    },

    goHome() {
      window.location.href = "/";
    },

    async checkPaymentStatus() {
      const paymentService = new PaymentService();
      const data = {
        payment_status: [
          {
            booking_number: this.bookings[0].booking_number,
          },
        ],
      };
      await paymentService.checkPaymentStatus(data);
      this.paymentStatus = paymentService.paymentStatus.value;
      if (this.paymentStatus.success) {
        this.isLoading = false;
        clearInterval(this.intervalId);
      }
    },

    generatePDF() {
      // perform this only when the payment is successfully
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [80, 297], // Example size for thermal printer roll (80mm width, height can vary)
      });

      // Add text or content to the PDF
      doc.text("This is a simple receipt.", 10, 10);
      doc.text("Item 1: $10", 10, 20);
      doc.text("Item 2: $20", 10, 30);
      doc.text("Total: $30", 10, 40);

      // Download the PDF (can be sent directly to a printer as well)
      doc.save("receipt.pdf");

      // To print directly (works in some browsers)
      window.open(doc.output("bloburl"));
    },

    startTimer() {
      setTimeout(async () => {
        // Clear the interval (if it exists)
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }

        // Await the result of checkPaymentStatus
        await this.checkPaymentStatus();

        // Check the payment status and handle failure
        if (!this.paymentStatus.success) {
          this.toast.error("The payment was not successful. Please try again");
          this.goHome();
        }
      }, 180000); // 180,000 ms = 3 minutes
    },
  },
  created() {
    this.busDetails = sessionManager.getBusDetails();
    this.fromName = sessionManager.fromName.value;
    this.toName = sessionManager.toName.value;
    this.tripDate = sessionManager.date.value;
    this.bookings = sessionManager.getBookingDetails();
    // this.toast("Default toast");
    // this.toast.info("Info toast");
    // this.toast.success("Success toast");
    // this.toast.error("Error toast");
    // this.toast.warning("Warning toast");
  },
};
</script>

<style scoped>
.custom-rounded {
  border-radius: 0 0 16px 16px;
}
.custom-dotted-line {
  transform: translateY(-2px);
  top: -10px;
  height: 4px; /* Adjust height of the line */
  background-image: repeating-linear-gradient(
    to right,
    #22b247 0,
    #22b247 4px,
    transparent 4px,
    transparent 8px
  );
  background-size: 16px 4px; /* Adjust the spacing and size of the boxes */
}

.body-section {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.bg-white-opacity {
  background-color: rgba(0, 0, 0, 0.5);
}

.shadow-no-offset {
  box-shadow: 0 0 10px rgba(255, 212, 40, 0.5);
}

.page-wrapper {
  overflow-x: hidden;
}

.header-section {
  position: relative;
  text-align: center;
  padding: 20px;
  height: 360px;
  background-image: url("@/assets/images/mumuk-hero.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  z-index: 1; /* Position the overlay behind the content */
}

.header-section * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.journey-date {
  margin-left: 260px;
  margin-right: 260px;
}

.seat-layout-section {
  margin: 60px 160px;
}

.seats-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seat-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.seat {
  color: #333;
  font-size: 12px;
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 2px 2px 8px 8px;
  background-color: #f5f5f5;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.seat-active {
  background-color: #e0e5e8;
  cursor: pointer;
}

.seat-reserved {
  background-color: #108647;
  cursor: not-allowed;
  color: white;
}

.seat-active:hover {
  transform: scale(0.9);
  border: none;
  background-color: #ea8e67;
  color: white;
}

.selected-seat {
  background-color: #c85424;
  color: white;
}
.selected-seat:hover {
  background-color: #9b421c;
}

.seat-gap {
  background-color: transparent;
  border: none;
}

.seat-door {
  background-color: transparent;
  border: none;
}
.seat-driver {
  width: 30px;
  height: 30px;
  border: 3px solid #c9c5c5;
  border-radius: 50%;
  background-color: transparent;
}

.available-bus-item {
  width: 100%;
  position: relative;
  background-color: white;
  border: #e2dedc7e 0.1px solid;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.check-item i {
  padding: 5px 4px 2px 4px;
  color: white;
  font-size: 10px;
  background-color: #22b247;
  border-radius: 12px;
}

.bus-name {
  font-size: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.611);
  color: white;
  font-weight: 500;
}

.available-bus-item img {
  height: 200px;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.available-bus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
}

@media (max-width: 1184px) {
  .seat-layout-section {
    margin: 60px 80px;
  }
  .journey-date {
    margin-left: 100px;
    margin-right: 100px;
  }
}
@media (max-width: 820px) {
  .seat-layout-section {
    margin: 40px 60px;
  }
}

@media (max-width: 768px) {
  .journey-date {
    margin-left: 0px;
    margin-right: 0px;
  }
  .seat-layout-section {
    margin: 20px 20px;
  }
  .header-section {
    height: 500px !important;
  }
}

@media (max-width: 576px) {
  .header-section {
    height: 500px !important;
  }
}
</style>
