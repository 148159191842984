import "./assets/styles/main.css";
import "@vueform/multiselect/themes/default.css";
import "./assets/animation.js";
import "./index.css";
import "vue-toastification/dist/index.css";

import { createApp } from "vue";
import App from "./App.vue";
import Toast from "vue-toastification";
import router from "./core/router/index.js";

const app = createApp(App);

app.mixin({
  methods: {
    formatNumber(value) {
      return new Intl.NumberFormat().format(value);
    },

    twelveHourFormat(time24) {
      if (!time24 || typeof time24 !== "string" || !time24.includes(":")) {
        return time24;
      }

      const [hours, minutes] = time24.split(":").map(Number);

      if (isNaN(hours) || isNaN(minutes)) {
        return time24;
      }

      const period = hours >= 12 ? "PM" : "AM";
      const hours12 = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, "0");

      return `${hours12}:${formattedMinutes} ${period}`;
    },
  },
});

app.use(router);
app.use(Toast);

app.mount("#app");
