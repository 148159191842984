export function initializeAnimations() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("item-show");
      }
    });
  });

  const hiddenElements = document.querySelectorAll(
    ".item-hidden-left, .item-hidden-right, .item-hidden-bottom"
  );

  hiddenElements.forEach((el) => observer.observe(el));

  const navToggle = document.querySelector(".nav__toggle");

  if (!navToggle.classList.contains("event-attached")) {
    navToggle.addEventListener("click", () => {
      document.querySelector(".top-nav-bar").classList.toggle("open");
      document.querySelector(".top-nav-bar-elements").classList.toggle("show");
    });
    navToggle.classList.add("event-attached");
  }

  const navElements = document.querySelectorAll(".top-nav-bar-element");

  navElements.forEach((el) => {
    el.addEventListener("click", () => {
      document.querySelector(".top-nav-bar").classList.remove("open");
      document.querySelector(".top-nav-bar-elements").classList.remove("show");
    });
  });
}

document.addEventListener("DOMContentLoaded", initializeAnimations);
