<template>
  <div @click="onToggle" class="custom-switch">
    <div :class="['switch-label', { on: value }]">{{ labels[0] }}</div>
    <div :class="['switch-label', { on: !value }]">{{ labels[1] }}</div>
    <div
      :class="['switch-thumb', { 'thumb-left': value, 'thumb-right': !value }]"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true,
      validator: (value) => value.length === 2,
    },
    value: {
      type: Boolean,
      required: true,
    },
    onToggle: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.custom-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  height: 34px;
  border: 2px solid #22b247;
  border-radius: 20px;
  background-color: #eee;
  position: relative;
  user-select: none;
  font-size: 10px;
}

.switch-label.on {
  z-index: 2;
  color: white;
}

.custom-switch .switch-label {
  display: inline-block;
  padding: 5px 10px;
}

.switch-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  transition: all 0.3s;
  background-color: #22b247;
}

.thumb-left {
  transform: translateX(0);
  border-radius: 20px 0px 0px 20px;
}

.thumb-right {
  transform: translateX(100%);
  border-radius: 0px 20px 20px 0px;
}
</style>
