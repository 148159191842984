<template>
  <div class="wrapper">
    <div class="gallery-left item-hidden-left">
      <img :src="imageSrc" alt="Gallery image showcasing bus travel" />
    </div>
    <div class="gallery-right">
      <div class="flex flex-row items-center justify-between gap-4">
        <div>
          <h2 class="highlight item-hidden-right h2">Gallery</h2>
          <h1 class="h1">Celebrating Tanzania's Bus Travel</h1>
        </div>
        <button class="primary-button w-[150px]" @click="goToGallery">
          View All
        </button>
      </div>
      <p class="p">
        Browse our gallery to witness the scenic landscapes and unforgettable
        moments from our bus journeys. At Mumuk Investment, travel is more than
        just reaching a destination—it’s about experiencing the diverse beauty
        and culture of Tanzania along the way.
      </p>
    </div>
  </div>
</template>

<script>
import galleryImg from "@/assets/images/gallery.jpeg";

export default {
  data() {
    return {
      imageSrc: galleryImg,
    };
  },
  methods: {
    goToGallery() {
      this.$router.push("/gallery");
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  height: 100%;
}

.gallery-right,
.gallery-left {
  flex: 1;
}

.gallery-right {
  padding: 20px;
}

.gallery-left {
  height: 400px;
  display: flex;
}

.gallery-left img {
  border-radius: 0px;
  height: 100%;
  width: auto;
  object-fit: cover;
  flex-grow: 1;
}

@media (max-width: 1136px) {
  .gallery-left {
    height: 440px;
  }
}

@media (max-width: 989px) {
  .gallery-left {
    height: 470px;
  }
}

@media (max-width: 821px) {
  .gallery-left {
    height: 535px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column-reverse;
    height: auto;
  }

  .gallery-left {
    height: 300px; /* Give a fixed height for smaller screens */
  }
}
</style>
