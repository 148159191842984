<template>
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      loader="spinner"
      background-color="#000"
      color="#fff"
      :opacity="0.3"
      :width="34"
      :height="34"
      :is-full-page="true"
    />
  </div>

  <div class="landing-page">
    <!-- ********    MD-NAVBAR SECTION    ******** -->
    <MdNavBar
      :onClickHome="() => scrollToSection('header-section')"
      :onClickAboutUs="() => scrollToSection('about-section')"
      :onClickServices="() => scrollToSection('our-services-section')"
      :onClickOurBus="() => scrollToSection('our-bus-section')"
      :onClickGallery="() => scrollToSection('gallery-section')"
      :onClickHelpCenter="() => scrollToSection('footer-section')"
    />
    <!-- ********    END MD-NAVBAR SECTION    ******** -->

    <!-- ********    TOP-HEADER SECTION    ******** -->
    <TopHeader />
    <!-- ********    END TOP-HEADER SECTION    ******** -->

    <!-- ********    HEADER SECTION    ******** -->
    <section id="header-section" class="header-section">
      <header>
        <div class="nav-bar">
          <div class="nav-bar-logo hidden md:flex flex-row items-center">
            <!-- <div class="text-theme text-[36px] font-bold pb-1">Mumuk</div> -->
            <img :src="logoImg" alt="Logo" class="app-logo h-[120px]" />
          </div>
          <div class="nav-bar-item">Home</div>
          <div @click="scrollToSection('about-section')" class="nav-bar-item">
            About Us
          </div>
          <div
            @click="scrollToSection('our-services-section')"
            class="nav-bar-item"
          >
            Services
          </div>
          <div @click="scrollToSection('our-bus-section')" class="nav-bar-item">
            Our Bus
          </div>
          <div @click="scrollToSection('gallery-section')" class="nav-bar-item">
            Gallery
          </div>
          <div @click="scrollToSection('footer-section')" class="nav-bar-item">
            Help Center
          </div>
        </div>
      </header>
      <div class="top-content">
        <div class="top-content-right">
          <h1 class="item-hidden-bottom h1">
            Discover the Premier
            <div class="highlight text-second">Choice for Intercity</div>
            Travel in Tanzania
          </h1>
          <p class="item-hidden-right p">
            Embark on a journey with Mumuk Investment, the pinnacle of intercity
            bus travel in Tanzania. Our services promise reliability,
            comfort,<br />
            and convenience, making every trip between cities or across the
            country a seamless experience. Trust Mumuk Investment for a
            journey<br />
            that's both secure and enjoyable, every mile of the way.
          </p>
        </div>
      </div>
    </section>
    <!-- ********    END-HEADER SECTION    ******** -->

    <!-- ********    FORM SECTION    ******** -->
    <section id="form-section" class="form-section">
      <form
        class="form-container item-hidden-bottom"
        @submit="navigateToJourney"
      >
        <div class="form-inputs">
          <div class="input-element">
            <label for="from">From</label>
            <!-- <input
              type="text"
              id="from"
              name="from"
              placeholder="Departure location"
              required
            /> -->
            <multiselect
              class="containerActive"
              v-model="from"
              :options="
                regions.map((region) => ({
                  value: region.id,
                  label: region.name,
                }))
              "
              :searchable="true"
              :required="true"
              :close-on-select="true"
              :show-labels="false"
              @clear="exampleClick"
              placeholder="Select region"
            ></multiselect>
          </div>
          <div class="input-element">
            <label for="to">To</label>
            <!-- <input
              type="text"
              id="to"
              name="to"
              placeholder="Destination"
              required
            /> -->
            <multiselect
              class="containerActive"
              v-model="to"
              :options="
                regions.map((region) => ({
                  value: region.id,
                  label: region.name,
                }))
              "
              :searchable="true"
              :required="true"
              :close-on-select="true"
              :show-labels="false"
              @clear="exampleClick"
              placeholder="Select region"
            ></multiselect>
          </div>
          <div class="input-element">
            <label for="date">Date</label>
            <input
              type="date"
              id="date"
              v-model="date"
              name="date"
              :disabled="isDateDisabled"
              :min="minDate"
              required
            />
          </div>
        </div>
        <button type="submit" class="primary-button">Submit</button>
      </form>
    </section>

    <!-- ********    ABOUT SECTION    ******** -->
    <section id="about-section" class="about-section">
      <div class="about-section-left">
        <h2 class="highlight item-hidden-right h2">About Us</h2>
        <h1 class="h1">Your Go-To Intercity Bus Partner</h1>
        <p class="p">
          At Mumuk Investment, we have years of experience in bridging the
          gap<br />
          between cities. We’re dedicated to delivering safe, punctual, and<br />
          comfortable bus services throughout Tanzania. Our top priority is<br />
          customer satisfaction, ensuring smooth and pleasant journeys,
          whether<br />
          you're traveling a short distance or embarking on a longer
          adventure.<br />
        </p>
        <div class="check-items">
          <div class="check-items-left">
            <div class="check-item">
              <span
                ><i class="fi fi-br-check"></i>Exceptional Customer
                Service</span
              >
            </div>
            <div class="check-item">
              <span><i class="fi fi-br-check"></i>24/7 Support</span>
            </div>
          </div>
          <div class="check-items-right">
            <div class="check-item">
              <span
                ><i class="fi fi-br-check"></i>Comfort and Safety
                Guaranteed</span
              >
            </div>
            <div class="check-item">
              <span><i class="fi fi-br-check"></i>Easy Booking Experience</span>
            </div>
          </div>
        </div>
        <!-- <button class="primary-button">More About Us</button> -->
      </div>
      <div class="about-section-right">
        <img :src="imageSrc" alt="Description of image" />
      </div>
    </section>
    <!-- ********    END ABOUT SECTION    ******** -->

    <!-- ********    CHOOSE US SECTION    ******** -->
    <section id="choose-us-section" class="choose-us-section">
      <div class="choose-us-section-left item-hidden-left">
        <img :src="whyUsImgSrc" alt="Description of image" />
      </div>
      <div class="choose-us-section-right item-hidden-right">
        <h2 class="highlight h2">Why Choose Us</h2>
        <h1 class="h1">
          <div>Why Choose Mumuk Investment?</div>
        </h1>
        <p class="p">
          As a premier intercity bus operator headquartered in Arusha, Mumuk
          Investment<br />
          stands out for its dependable services. Here’s why you should choose
          us:
        </p>
        <div class="choose-us-items">
          <div class="choose-us-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-bus-front-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"
              />
            </svg>
            <div>
              <div class="choose-us-item-col">
                <h1>19</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                    />
                  </svg>
                </span>
              </div>
              <div>Buses in Fleet</div>
            </div>
          </div>

          <div class="choose-us-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-hand-thumbs-up"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"
              />
            </svg>
            <div>
              <div class="choose-us-item-col">
                <h1>90K</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                    />
                  </svg>
                </span>
              </div>
              <div>Happy Passengers</div>
            </div>
          </div>

          <div class="choose-us-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-patch-check-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708"
              />
            </svg>
            <div>
              <div class="choose-us-item-col">
                <h1>90K</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                    />
                  </svg>
                </span>
              </div>
              <div>Journeys Completed</div>
            </div>
          </div>

          <div class="choose-us-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-people"
              viewBox="0 0 16 16"
            >
              <path
                d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"
              />
            </svg>
            <div>
              <div class="choose-us-item-col">
                <h1>25</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                    />
                  </svg>
                </span>
              </div>
              <div>Dedicated and Skilled Team</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ********    END CHOOSE US SECTION    ******** -->

    <!-- ********    OUR-SERVICES SECTION    ******** -->
    <section id="our-services-section" class="our-services-section">
      <OurServices />
    </section>
    <!-- ********    OUR-SERVICES SECTION    ******** -->

    <!-- ********    OUR-BUS SECTION    ******** -->
    <section id="our-bus-section" class="our-bus-section">
      <OurBus :journeyList="journeyListData" />
    </section>
    <!-- ********    OUR-BUS SECTION    ******** -->

    <!-- ********    OUR-ROUTES SECTION    ******** -->
    <section id="our-routes-section" class="our-routes-section">
      <OurRoutes :journeyList="journeyListData" />
    </section>
    <!-- ********    OUR-ROUTES SECTION    ******** -->

    <!-- ********    Gallery SECTION    ******** -->
    <section id="gallery-section" class="gallery-section item-hidden-bottom">
      <Gallery />
    </section>
    <!-- ********    END Gallery SECTION    ******** -->

    <!-- ********    FOOTER SECTION    ******** -->
    <section id="footer-section" class="footer-section item-hidden-left">
      <Footer />
    </section>
    <!-- ********    END FOOTER SECTION    ******** -->
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import Footer from "../../components/Footer.vue";
import TopHeader from "../../components/TopHeader.vue";
import MdNavBar from "../../components/MdNavBar.vue";
import OurServices from "./components/OurServices.vue";
import OurBus from "./components/OurBus.vue";
import OurRoutes from "./components/OurRoutes.vue";
import Gallery from "./components/Gallery.vue";

import passengersImage from "@/assets/images/bus_inside.jpg";
import whyUsImg from "@/assets/images/mumuk-why-us.jpeg";
import logoImgSrc from "@/assets/images/Mumuk-Logo.png";

import JourneyService from "../../../core/services/journeyService.js";
import AgentService from "../../../core/services/agentService.js";
import RegionService from "../../../core/services/regionService.js";
import sessionManager from "../../../core/utils/sessionManager.js";

import { initializeAnimations } from "../../../assets/animation.js";

export default {
  data() {
    return {
      isLoading: false,
      imageSrc: passengersImage,
      whyUsImgSrc: whyUsImg,
      regions: [],
      from: "",
      to: "",
      date: "",
      minDate: "",
      journeyListData: [],
      logoImg: logoImgSrc,
    };
  },
  components: {
    Loading,
    Multiselect,
    Footer,
    TopHeader,
    MdNavBar,
    OurServices,
    OurBus,
    OurRoutes,
    Gallery,
  },
  computed: {
    isDateDisabled() {
      return !this.from || !this.to;
    },
  },
  methods: {
    async getJourneyList() {
      // this.isLoading = true;
      const journeyService = new JourneyService();
      await journeyService.getCompanyTripList();
      this.journeyListData = journeyService.companyTripListData.value;
      this.message = journeyService.message.value;
      // this.isLoading = false;
    },
    exampleClick() {},
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async fetchData() {
      // this.isLoading = true;
      const agentService = new AgentService();
      const result = await agentService.getUserToken();
      if (result) {
        this.getRegions();
        await this.getJourneyList();
      }
      // this.isLoading = false;
    },

    async getRegions() {
      const regions = sessionManager.getRegions();
      const regionService = new RegionService();
      if (regions === null || regions.length === 0) {
        console.log("REGIONS not found");
        await regionService.getRegions();
        this.regions = regionService.regionsData.value;
        sessionManager.storeRegions(this.regions);
      } else {
        console.log("REGIONS found");
        this.regions = regions;
      }
    },
    navigateToJourney(event) {
      event.preventDefault();

      if (!this.from || !this.to || !this.date) {
        return;
      }

      const fromName = this.regions.find((reg) => reg.id === this.from).name;
      const toName = this.regions.find((reg) => reg.id === this.to).name;

      sessionManager.storeTripDetails(
        fromName,
        toName,
        this.from,
        this.to,
        this.date
      );

      console.log(
        "from: " +
          fromName +
          " to: " +
          toName +
          " fromId: " +
          this.from +
          " toId: " +
          this.to +
          " date: " +
          this.date
      );

      this.$router.push({
        path: "/journey",
      });
    },
  },
  mounted() {
    const today = new Date();
    const tomorrow = new Date(today);
    this.minDate = tomorrow.toISOString().split("T")[0];
    this.date = tomorrow.toISOString().split("T")[0];
    initializeAnimations();
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.landing-page {
  position: relative;
  overflow-x: hidden;
}

.header-section {
  position: relative; /* Important for positioning the overlay */
  text-align: center;
  padding: 20px;
  height: 600px;
  background-image: url("@/assets/images/mumuk-hero.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden; /* Ensure children don't overflow */
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  z-index: 1; /* Position the overlay behind the content */
}

.header-section * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

header {
  background: #f4f4f4;
  color: #000;
  padding: 0 20px 0 0;
  border-radius: 8px;
  margin: 50px 200px 0 200px;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-bar-item {
  font-weight: 500;
  cursor: pointer;
  font-size: medium;
  transition: color 400ms ease-in-out;
}

.nav-bar-item:hover {
  color: #22b247;
}

.form-section {
  position: absolute;
  left: 0;
  right: 0;
  top: 490px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.form-container {
  width: 70%;
  max-width: 900px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.form-inputs {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}

.form-container input {
  width: 100%;
  padding: 8px 16px;
  /* height: 50px; */
  font-size: 16px;
}

.input-element {
  width: 100%;
}

.form-container label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-container button {
  margin-top: 10px;
}

.about-section {
  margin-top: 140px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center; /* Center items vertically */
  justify-items: center; /* Center items horizontally */
  gap: 20px; /* Adjust the gap size as needed */
}

.about-section-right img {
  width: 100%;
  border-radius: 12px;
}

.about-section-right {
  width: 100%;
}

.about-section p,
.check-items {
  color: #a1a1a1;
}

.check-items {
  display: flex;
  gap: 20px;
}

.check-items-left,
.check-items-right {
  flex: 1;
}

.check-item {
  margin-bottom: 10px;
}

.check-items i {
  padding: 5px 4px 2px 4px;
  color: white;
  font-size: 10px;
  background-color: #22b247;
  border-radius: 12px;
  margin-right: 10px;
}

.choose-us-section {
  margin-top: 80px;
  margin-left: 0;
  margin-right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center; /* Center items vertically */
  justify-items: center; /* Center items horizontally */
  gap: 0; /* Adjust the gap size as needed */
}

.choose-us-section-right {
  padding: 50px;
  background-color: #000;
  height: 100%;
}

.choose-us-section-left {
  height: 100%;
}

.choose-us-section-left img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.choose-us-section-right h1 {
  color: white;
}
.choose-us-section-right p {
  color: #a1a1a1;
}

.choose-us-section-left,
.choose-us-section-left img {
  width: 100%;
}

.choose-us-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.choose-us-item {
  display: flex;
  align-items: center;
  justify-content: start;
  color: #a1a1a1;
  gap: 20px;
  margin-bottom: 20px;
}

.choose-us-item svg {
  color: #22b247;
}

.choose-us-item-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}
</style>
