import { ref } from "vue";
import axiosInstance from "../config/axiosConfig.js";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

class JourneyService {
  constructor() {
    this.success = ref(false);
    this.message = ref("");
    this.errors = ref({});

    this.journeyListData = ref([]);
    this.idTypeListData = ref([]);
    this.ageGroupListData = ref([]);
    this.companyTripListData = ref([]);
    this.bookingListData = ref([]);
    this.journeyData = ref({});

    // Initialize toast and router
    this.toast = useToast();
    this.router = useRouter();
  }

  async searchJourney(data) {
    try {
      const response = await axiosInstance.post(
        `/passenger/company_search_trips`,
        data
      );

      // console.log("RESPONSE: " +response.data);

      if (response.data.success) {
        this.journeyListData.value = response.data.journeys;

        if (this.journeyListData.value.length === 0) {
          this.message.value = "No bus found for the selected route and date.";
        }
      } else {
        this.message.value = response.data.message;
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while searching journey:", e);
      }
    }
  }

  async bookTicket(data) {
    try {
      const response = await axiosInstance.post(`/passenger/book-ticket`, data);

      response.data.success;
      response.data.message;

      this.success.value = response.data.success;
      this.message.value = response.data.message;

      if (response.data.success) {
        this.bookingListData.value = response.data.bookings;
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while booking ticket:", e);
      }
    }
  }

  async getJourney(id) {
    try {
      const response = await axiosInstance.get(`/passenger/search-trips/${id}`);

      if (response.data.success) {
        this.journeyData.value = response.data;
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while getting journey details:", e);
      }
    }
  }

  async getIdTypes() {
    try {
      const response = await axiosInstance.get(`/passenger/id-types`);

      if (response.data.success) {
        this.idTypeListData.value = response.data.id_types;
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while getting id types:", e);
      }
    }
  }

  async getAgeGroups() {
    try {
      const response = await axiosInstance.get(`/passenger/age-groups`);

      if (response.data.success) {
        this.ageGroupListData.value = response.data.age_groups;
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while getting age groups:", e);
      }
    }
  }

  async getCompanyTripList() {
    try {
      const response = await axiosInstance.get(`/company_trips`, {
        params: {
          company_id: 104,
        },
      });

      if (response.data.success) {
        this.companyTripListData.value = response.data.trips;
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while getting company trip list:", e);
      }
    }
  }

  async checkSeatAvailability(data) {
    try {
      const response = await axiosInstance.post(
        `/booking/check-seat-availability`,
        data
      );

      this.success.value = response.data.success;
      this.message.value = response.data.message;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while booking ticket:", e);
      }
    }
  }
}

export default JourneyService;
