<template>
  <!-- BUS IMAGES Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{
              selectedBus
                ? selectedBus.vehicle.company +
                  " - " +
                  selectedBus.vehicle.registration_number
                : "Bus name"
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="selectedBus != null" class="modal-body image-grid">
          <img
            v-for="(image, index) in selectedBus
              ? selectedBus.vehicle.images
              : []"
            :key="index"
            :src="image.image"
            alt="Bus"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="primary-button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- BUS AMENITIES Modal -->
  <div
    class="modal fade"
    id="busAmenitesModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="busAmenitesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="busAmenitesModalLabel">
            {{
              selectedBus
                ? selectedBus.vehicle.company +
                  " - " +
                  selectedBus.vehicle.registration_number +
                  " - AMENITIES"
                : "Bus name"
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="flex flex-col gap-3 m-3">
            <div
              v-if="selectedBus"
              v-for="(amenite, index) in selectedBus.vehicle.amenities"
              :key="index"
            >
              <div
                class="flex flex-row gap-3 bg-white rounded-lg p-2 shadow-sm items-center"
              >
                <img
                  :src="amenite.icon"
                  alt=""
                  srcset=""
                  style="width: 50px; height: 50px"
                  class="bg-gray-200 p-2 rounded-md"
                />
                <div class="flex flex-col">
                  <h3 class="font-bold text-xl text-theme">
                    {{ amenite.name }}
                  </h3>
                  <p class="text-gray-400 text-[12px]">
                    {{ amenite.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="primary-button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--   ****   LOADER   ****    -->
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      loader="spinner"
      background-color="#000"
      color="#fff"
      :opacity="0.3"
      :width="34"
      :height="34"
      :is-full-page="true"
    />
  </div>

  <div class="page-wrapper">
    <!-- ********    TOP-HEADER SECTION    ******** -->
    <TopHeader />
    <!-- ********    END TOP-HEADER SECTION    ******** -->

    <!-- ********    HEADER SECTION    ******** -->
    <div class="nav__toggle hidden"></div>
    <div class="header-section">
      <div class="top-content">
        <div class="top-content-right">
          <h1 class="h1">
            <div>Available</div>
            <div class="highlight text-second">Buses</div>

            <div>For You</div>
          </h1>
          <p class="p">
            Discover buses for every journey—reliable, comfortable, and on time,
            whether you're headed to the city or countryside.
          </p>
        </div>
      </div>
      <div
        class="journey-date item-hidden-left bg-white-opacity text-black text-sm flex flex-row justify-between rounded-lg"
      >
        <div class="flex flex-col items-start p-3">
          <div
            class="flex flex-row gap-2 text-white text-[10px] md:text-[16px]"
          >
            <div>{{ fromName }}</div>
            <i class="bi bi-arrow-right-short text-theme text-[20px]"></i>
            <div>{{ toName }}</div>
          </div>
          <div class="text-gray-400 text-[12px] md:text-[16px]">
            <div>{{ tripDate }}</div>
          </div>
        </div>
        <div
          @click="goBack"
          class="date-button flex flex-row justify-center items-center cursor-pointer bg-theme text-[14px] md:text-[16px] rounded-r-lg gap-2 p-3"
        >
          <i class="bi bi-chevron-left"></i>
          <button>Back</button>
        </div>
      </div>
    </div>
    <!-- ********    END HEADER SECTION    ******** -->

    <!-- ********    BODY SECTION    ******** -->
    <section class="body-section" id="body-section">
      <div
        v-if="journeyListData.length === 0 && !isLoading"
        class="flex flex-col justify-center items-center gap-2"
      >
        <div
          class="flex flex-col justify-center items-center md:flex-row gap-2"
        >
          <div class=""><i class="bi bi-info-circle text-[36px]"></i></div>
          <div class="">{{ message }}</div>
        </div>
        <button class="primary-button" @click="goBack">
          Try a different search
        </button>
      </div>
      <div v-else class="available-buses item-hidden-bottom">
        <div
          v-if="!isLoading"
          v-for="(bus, index) in journeyListData"
          :key="index"
          class="available-bus-item"
        >
          <img
            v-if="bus.vehicle.images.length !== 0"
            :src="bus.vehicle.images[0].image"
            alt="Bus"
            @click="selectedBus = bus"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          />
          <img
            v-else
            :src="busImageSrc"
            alt="Bus"
            @click="toast.info('This bus has no Images.')"
          />
          <div class="available-bus-info">
            <div
              class="route-details flex flex-row justify-between text-gray-400 text-[12px] w-full"
            >
              <div class="from">From</div>
              <div>{{ bus.remained_seats }} Seats remain</div>
              <div class="to">To</div>
            </div>
            <div
              class="route-details grid grid-cols-3 flex-row justify-between items-center text-gray-700 text-[10px] w-full py-2 gap-2"
            >
              <div class="from col-span-1">
                <div class="font-bold text-[12px]">
                  {{ bus.trip_route.start_point_code }}
                </div>
                <div>{{ bus.trip_route.start_point }}</div>
                <div>{{ twelveHourFormat(bus.departing_time) }}</div>
              </div>
              <div class="flex flex-col justify-center items-center col-span-1">
                <i
                  class="bi bi-truck-front-fill bg-theme text-white px-2 py-1 text-[14px] rounded-[50%]"
                ></i>
                <div>{{ bus.trip_date }}</div>
              </div>
              <div class="to col-span-1 text-right">
                <div class="font-bold text-[12px]">
                  {{ bus.trip_route.end_point_code }}
                </div>
                <div>{{ bus.trip_route.end_point }}</div>
                <div>{{ twelveHourFormat(bus.arrival_time) }}</div>
              </div>
            </div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
            <div
              class="services text-[12px] flex flex-row items-center justify-center gap-2"
            >
              <div>{{ bus.vehicle.fleet_type }}</div>
              <div
                v-for="(amenite, index) in bus.vehicle.amenities"
                :key="index"
              >
                <img
                  :src="amenite.icon"
                  alt=""
                  srcset=""
                  style="width: 16px; height: 16px; border-radius: 0"
                  @click="selectedBus = bus"
                  data-bs-toggle="modal"
                  data-bs-target="#busAmenitesModal"
                />
              </div>
            </div>
            <button
              class="book-now-btn bg-second text-white font-bold my-3 px-5 py-2 text-[14px] rounded-[20px]"
              @click="goToSelectSeats(bus)"
            >
              Tsh {{ formatNumber(bus.fare) }}/=
            </button>
          </div>
          <div class="bus-name flex flex-row items-center p-2 rounded-md">
            <div>{{ bus.vehicle.company }}</div>
            <div class="w-[0.7px] h-3 bg-gray-300 mx-2"></div>
            <div>{{ bus.vehicle.registration_number }}</div>
          </div>
        </div>
      </div>
    </section>
    <!-- ********    END BODY SECTION    ******** -->

    <!-- ********    FOOTER SECTION    ******** -->
    <section id="footer-section " class="footer-section">
      <Footer />
    </section>
    <!-- ********    END FOOTER SECTION    ******** -->
  </div>
</template>

<script>
import bus from "@/assets/images/bus.jpg";
import JourneyService from "../../../core/services/journeyService.js";
import sessionManager from "../../../core/utils/sessionManager.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import Footer from "../../components/Footer.vue";
import TopHeader from "../../components/TopHeader.vue";

import { useToast } from "vue-toastification";
import { initializeAnimations } from "../../../assets/animation.js";

export default {
  data() {
    return {
      busDetails: {},
      fromName: "",
      toName: "",
      tripDate: "",
      busImageSrc: bus,
      isLoading: false,
      journeyListData: [],
      message: "",
      selectedBus: null,
    };
  },
  components: {
    Footer,
    Loading,
    TopHeader,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToSelectSeats(bus) {
      sessionManager.storeBusDetails(bus);
      this.$router.push({
        path: "/selectSeat",
        query: { id: bus.id },
      });
    },
    async searchJourneyList() {
      this.isLoading = true;
      const journeyService = new JourneyService();
      await journeyService.searchJourney({
        from: sessionManager.fromId.value,
        to: sessionManager.toId.value,
        trip_date: sessionManager.date.value,
        journey_type_id: 1,
        company_id: 104,
      });
      this.journeyListData = journeyService.journeyListData.value;
      this.message = journeyService.message.value;

      this.isLoading = false;
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
  mounted() {
    initializeAnimations();
  },
  created() {
    this.fromName = sessionManager.fromName.value;
    this.toName = sessionManager.toName.value;
    this.tripDate = sessionManager.date.value;
    // this.busDetails = sessionManager.getBusDetails();
    this.searchJourneyList();
  },
};
</script>

<style scoped>
.bg-white-opacity {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the last value (0.8) to control opacity */
}

.page-wrapper {
  /* position: relative; */
  overflow-x: hidden;
}

.header-section {
  position: relative;
  text-align: center;
  padding: 20px;
  height: 420px;
  background-image: url("@/assets/images/mumuk-hero.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  z-index: 1; /* Position the overlay behind the content */
}

.header-section * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.journey-date {
  margin-left: 260px;
  margin-right: 260px;
}

.body-section {
  margin: 60px 200px;
}

.available-buses {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 20px;
  width: 100%;
  place-items: center;
}

.available-bus-item {
  width: 100%;
  position: relative;
  background-color: white;
  border: #e2dedc7e 0.1px solid;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  transition: all 200ms ease-in-out;
}

.bus-name {
  font-size: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.611);
  color: white;
  font-weight: 500;
}

.available-bus-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
}

.available-bus-item:hover {
  transform: translateY(-5px);
  scale: 1.01;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.available-bus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
}

.available-bus-info svg {
  color: #c85424;
}

.modal-body {
  display: flex;
  justify-content: center; /* Center the grid horizontally */
  align-items: center; /* Center the grid vertically */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Space between grid items */
  max-width: 100%; /* Ensure grid doesn't overflow */
  justify-items: center; /* Center items in the grid */
  align-items: center; /* Center items vertically in the grid */
}

.image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.image-grid img:hover {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

@media (max-width: 1184px) {
  .journey-date {
    margin-left: 100px;
    margin-right: 100px;
  }
  .body-section {
    margin: 60px 80px;
  }
}
@media (max-width: 820px) {
  .body-section {
    margin: 40px 60px;
  }
}

@media (max-width: 768px) {
  .journey-date {
    margin-left: 0px;
    margin-right: 0px;
  }
  .available-buses {
    grid-template-columns: repeat(2, 1fr);
  }
  .body-section {
    margin: 20px 20px;
  }
  .header-section {
    height: 500px !important;
  }
}

@media (max-width: 576px) {
  .header-section {
    height: 500px !important;
  }
  .available-buses {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
