import { ref } from "vue";
import axiosInstance from "../config/axiosConfig.js";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

class PaymentService {
  constructor() {
    this.success = ref(false);
    this.message = ref("");
    this.errors = ref({});
    this.paymentGatewayChannelList = ref([]);
    this.idTypeListData = ref([]);
    this.ageGroupListData = ref([]);
    this.journeyData = ref({});
    this.paymentStatus = ref({});

    // Initialize toast and router
    this.toast = useToast();
    this.router = useRouter();
  }

  async getPaymentGatewayChannels() {
    try {
      // GET /api/payment/mobile-money-channels => AZAMPAY GATEWAY
      // GET /api/mobile-money-channels => PAWAPAY
      const response = await axiosInstance.get(
        `/payment/mobile-money-channels`
      );

      this.paymentGatewayChannelList.value = response.data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log(
        //   "Error occurred while getting payment gateway channels:",
        //   e
        // );
      }
    }
  }

  async makePayment(data) {
    try {
      const response = await axiosInstance.post(`/checkout/mno`, data);
      this.success.value = response.data.success;
      this.message.value = response.data.message;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while making payment:", e);
      }
    }
  }

  async checkPaymentStatus(data) {
    try {
      const response = await axiosInstance.post(
        `/passenger/checkPaymentStatus`,
        data
      );

      // {success: false, attempt_failed: false, message: 'Payment Incomplete'}
      this.paymentStatus.value = response.data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else if (e.response && e.response.status === 401) {
        this.toast.error("Session expired.");
        this.router.replace({ path: "/" });
      } else {
        // console.log("Error occurred while checking payment status:", e);
      }
    }
  }
}

export default PaymentService;
