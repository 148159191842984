<template>
  <footer class="footer text-white">
    <div class="footer-content grid grid-cols-12 gap-4 pt-14 pb-12">
      <div
        class="footer-col col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
      >
        <div class="flex flex-col">
          <div class="nav-bar-logo flex flex-row items-center">
            <!-- <div class="text-theme text-[36px] font-bold pb-1">Mumuk</div> -->
            <img
              :src="logoImg"
              alt="Logo"
              class="app-logo h-[100px] bg-white rounded-2xl"
            />
          </div>
          <p class="p text-[15px] text-[#a1a1a1] py-3">
            Discover new destinations with ease and convenience. Your journey
            starts here.
          </p>
          <div class="flex flex-row gap-3 icons">
            <!-- <a
              href="https://www.facebook.com/profile.php?id=61563272180262"
              class="bg-theme p-[12px]"
              target="_blank"
              rel="noopener noreferrer"
              data-social="Facebook"
              style="--accent-color: #0866ff"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                />
              </svg>
            </a>
            <a
              href="https://x.com/mysafari255"
              class="bg-theme p-[12px] rounded-md cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
              data-social="Twitter"
              style="--accent-color: black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-twitter-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
                />
              </svg>
            </a> -->
            <a
              href="https://www.instagram.com/mumuk_investement_co_ltd/"
              class="bg-theme p-[12px] rounded-md cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
              data-social="Instagram"
              style="--accent-color: #ff10ef"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-instagram"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
                />
              </svg>
            </a>
            <!-- <a
              href="https://www.tiktok.com/@mysafaritanzania"
              class="bg-theme p-[12px] rounded-md cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
              data-social="Tiktok"
              style="--accent-color: #38142a"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-tiktok"
                viewBox="0 0 16 16"
              >
                <path
                  d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"
                />
              </svg>
            </a>
            <a
              href="https://www.threads.net/@mysafaritanzania?hl=en"
              class="bg-theme p-[12px] rounded-md cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
              data-social="Threads"
              style="--accent-color: black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-threads"
                viewBox="0 0 16 16"
              >
                <path
                  d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161"
                />
              </svg>
            </a> -->
          </div>
        </div>
      </div>
      <div
        class="footer-col col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
      >
        <div class="text-[26px] font-bold">Quick Links</div>
        <div class="quid-links text-[15px] text-[#a1a1a1] pt-3">
          <a href="#" class="flex flex-row gap-3 mb-2">
            <i class="bi bi-chevron-right text-theme"></i>
            <div>About Us</div>
          </a>
          <a href="#" class="flex flex-row gap-3 mb-2">
            <i class="bi bi-chevron-right text-theme"></i>
            <div>Services</div>
          </a>
          <a href="#" class="flex flex-row gap-3 mb-2">
            <i class="bi bi-chevron-right text-theme"></i>
            <div>Projects</div>
          </a>
          <a href="#" class="flex flex-row gap-3 mb-2">
            <i class="bi bi-chevron-right text-theme"></i>
            <div>Pricing</div>
          </a>
          <a href="#" class="flex flex-row gap-3 mb-2">
            <i class="bi bi-chevron-right text-theme"></i>
            <div>Contact</div>
          </a>
        </div>
      </div>
      <div
        class="footer-col col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
      >
        <div class="text-[26px] font-bold">Contact Info</div>
        <div class="flex flex-col text-[14px] text-[#a1a1a1]">
          <div class="info-item flex flex-row gap-3 py-3">
            <i class="bi bi-geo-alt-fill text-theme"></i>
            <div>Arusha Stand, Arusha Tanzania</div>
          </div>
          <div class="w-full h-[0.07px] bg-gray-700"></div>
          <div
            class="info-item flex flex-row gap-3 py-3 cursor-pointer"
            @click="openDialer"
          >
            <i class="bi bi-telephone-fill text-theme"></i>
            <!-- <div>+255 753 876 198</div> -->
            <div>+255 736 969 594</div>
          </div>
          <div class="w-full h-[0.07px] bg-gray-700"></div>
          <!-- <div
            class="info-item flex flex-row gap-3 py-3 cursor-pointer"
            @click="openEmail"
          >
            <i class="bi bi-geo-alt-fill text-theme"></i>
            <div>support@mysafari.co.tz</div>
          </div> -->
        </div>
      </div>
      <div class="footer-col col-span-12 xl:col-span-3">
        <div class="text-[26px] font-bold">Newsletter</div>
        <p class="p text-[15px] text-[#a1a1a1] py-2">
          Stay updated with the latest news, promotions, and travel tips. Sign
          up for our newsletter today!
        </p>
        <form action="" class="flex flex-row pt-3">
          <input
            type="email"
            class="w-full text-black px-4 py-3 focus:outline-none focus:ring-0rounded-l-md rounded-r-[0px]"
            placeholder="Enter your email"
            required
          />
          <button
            type=""
            class="px-4 py-3 text-white font-bold bg-theme rounded-l-[0px] rounded-r-md"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
    <div class="w-full h-[0.07px] bg-gray-700"></div>
    <div
      class="flex flex-col md:flex-row justify-between py-6 gap-3 text-[14px] text-[#a1a1a1]"
    >
      <div class="">Mumuk Investment - Your Trusted Partner in Bus Travel</div>
      <p>Copyright &copy; 2024. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
import logoImgSrc from "@/assets/images/Mumuk-Logo.png";

export default {
  name: "Footer",
  data() {
    return { logoImg: logoImgSrc };
  },
  methods: {
    openDialer() {
      // const dialerUrl = "tel:+255736969594";
      const dialerUrl = "tel:+255736969594";
      window.open(dialerUrl, "_self");
    },
    openEmail() {
      const subject = "Inquiry about services";
      const body = "Hello, I would like to inquire about your services.";
      const mailtoUrl = `mailto:support@mysafari.co.tz?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.open(mailtoUrl, "_self");
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #000;
  color: #22b247;
  padding-left: 160px;
  padding-right: 160px;
  text-align: start;
}

.footer-content {
  margin: auto;
}

.footer-nav {
  margin-top: 10px;
}

.footer-nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.icons a {
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
  transition: transform 0.4s ease, background-color 0.4s ease;
}

.icons a::before {
  content: attr(data-social);
  /* z-index: 9; */
  position: absolute;
  background-color: var(--accent-color);
  /* background: linear-gradient(217deg, #e66465, #9198e5); */
  text-decoration: none;
  font-size: 13px;
  padding: 0.5em 1em;
  border-radius: 100px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
  transform: translateY(-20px) rotate(30deg);
  opacity: 0;
  transition: 200ms cubic-bezier(0.42, 0, 0.44, 1.68);
}

.icons a::after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid var(--accent-color);
  transform: translateY(-18px) rotate(30deg);
  opacity: 0;
  transition: 200ms cubic-bezier(0.42, 0, 0.44, 1.68);
}

.icons a:hover {
  background-color: var(--accent-color);
}

.icons a:hover::before {
  transform: translateY(-48px) rotate(0);
  opacity: 1;
}

.icons a:hover::after {
  transform: translateY(-29px) rotate(0);
  opacity: 1;
}

.quid-links a,
.info-item {
  transition: all 0.4s ease;
}
.quid-links a:hover,
.info-item:hover {
  color: #ffff;
}

@media (max-width: 1184px) {
  .footer {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
