import { ref } from "vue";

class SessionManager {
  constructor() {
    this.fromName = ref(localStorage.getItem("fromName") || "");
    this.fromId = ref(parseInt(localStorage.getItem("fromId"), 10) || 0);
    this.toName = ref(localStorage.getItem("toName") || "");
    this.toId = ref(parseInt(localStorage.getItem("toId"), 10) || 0);
    this.date = ref(localStorage.getItem("tripDate") || "");
  }

  storeTripDetails(fromName, toName, fromId, toId, date) {
    this.fromName.value = fromName;
    this.toName.value = toName;
    this.fromId.value = fromId;
    this.toId.value = toId;
    this.date.value = date;

    localStorage.setItem("fromName", fromName);
    localStorage.setItem("toName", toName);
    localStorage.setItem("fromId", fromId);
    localStorage.setItem("toId", toId);
    localStorage.setItem("tripDate", date);
  }
  storeBusDetails(busDetails) {
    localStorage.setItem("busDetails", JSON.stringify(busDetails));
  }

  getBusDetails() {
    return JSON.parse(localStorage.getItem("busDetails"));
  }

  storeBookingDetails(bookingDetails) {
    localStorage.setItem("bookingDetails", JSON.stringify(bookingDetails));
  }

  getBookingDetails() {
    return JSON.parse(localStorage.getItem("bookingDetails"));
  }

  storeRegions(regions) {
    localStorage.setItem("regions", JSON.stringify(regions));
  }

  getRegions() {
    return JSON.parse(localStorage.getItem("regions"));
  }

  storeIdTypes(idTypes) {
    localStorage.setItem("id_types", JSON.stringify(idTypes));
  }

  getIdTypes() {
    return JSON.parse(localStorage.getItem("id_types"));
  }

  storeAgeGroups(ageGroups) {
    localStorage.setItem("age_groups", JSON.stringify(ageGroups));
  }

  getAgeGroups() {
    return JSON.parse(localStorage.getItem("age_groups"));
  }
}

const sessionManager = new SessionManager();
export default sessionManager;
