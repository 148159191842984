import { ref } from "vue";
import axiosInstance from "../config/axiosConfig.js";

class AgentService {
  constructor() {
    //     this.authorizeActionData = ref(false);
    //     this.authorizeActionsData = ref([]);
  }

  async verifyOtp() {
    try {
      const body = {
        phone_number: "255715000011",
        otp: "8261",
        website_agent: true,
      };
      const response = await axiosInstance.post(`/verify-otp`, body);
      //       this.authorizeActionData.value = response.data;
      // console.log("SETTING ACCESS TOKEN : " + response.data.user.access_token);

      localStorage.setItem("access_token", response.data.user.access_token);
      return true;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors.value = e.response.data.errors;
        // console.log(response.data);
      } else {
        // console.log("Error occurred while verifying agent OTP:", e);
      }
      return false;
    }
  }

  async getUserToken() {
    try {
      const body = {
        phone_number: "255715000011",
      };
      // await this.verifyOtp();
      const response = await axiosInstance.post(`/get-user-token`, body);

      const data = response.data;
      // console.log("TOKEN RESPONSE: " + JSON.stringify(data));

      if (!data.success || data.invalid) {
        await this.verifyOtp();
      } else {
        localStorage.setItem("access_token", data.access_token);
        return true;
      }
    } catch (e) {
      this.handleError(e);
      return false;
    }
  }
}

export default AgentService;
