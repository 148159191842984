<template>
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      loader="spinner"
      background-color="#000"
      color="#fff"
      :opacity="0.4"
      :width="45"
      :height="45"
      :is-full-page="true"
    />
  </div>

  <!-- ********    MD-NAVBAR SECTION    ******** -->
  <MdNavBar
    :onClickHome="() => goToHome()"
    :onClickAboutUs="() => goToHome()"
    :onClickServices="() => goToHome()"
    :onClickOurBus="() => goToHome()"
    :onClickGallery="() => {}"
    :onClickHelpCenter="() => goToHome()"
  />
  <!-- ********    END MD-NAVBAR SECTION    ******** -->

  <!-- ********    TOP-HEADER SECTION    ******** -->
  <TopHeader />
  <!-- ********    END TOP-HEADER SECTION    ******** -->

  <!-- ********    HEADER SECTION    ******** -->
  <section id="header-section" class="header-section">
    <header>
      <div class="nav-bar">
        <div class="nav-bar-logo hidden md:flex flex-row items-center">
          <img :src="logoImg" alt="Logo" class="app-logo h-[120px]" />
        </div>
        <div class="nav-bar-item">Home</div>
        <div @click="goToHome" class="nav-bar-item">About Us</div>
        <div @click="goToHome" class="nav-bar-item">Services</div>
        <div @click="goToHome" class="nav-bar-item">Our Bus</div>
        <div @click="goToHome" class="nav-bar-item">Gallery</div>
        <div @click="goToHome" class="nav-bar-item">Help Center</div>
      </div>
    </header>
    <div class="top-content">
      <div class="top-content-right">
        <h1 class="h1">
          Discover Beauty, <br />
          <span class="highlight text-second">Capture</span> the Moment
        </h1>
      </div>
    </div>
  </section>
  <!-- ********    END-HEADER SECTION    ******** -->

  <body class="grey-bg">
    <main>
      <!-- images-area-start -->
      <div v-if="images.length > 0" class="images">
        <div v-for="(image, index) in images" :key="index" class="image-item">
          <img :src="image" alt="Image" />
        </div>
      </div>
      <!-- images-area-end -->
    </main>
  </body>
  <!-- ********    FOOTER SECTION    ******** -->
  <section id="footer-section " class="footer-section">
    <Footer />
  </section>
  <!-- ********    END FOOTER SECTION    ******** -->
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import logoImgSrc from "@/assets/images/Mumuk-Logo.png";

import JourneyService from "../../../core/services/journeyService.js";

import Footer from "../../components/Footer.vue";
import TopHeader from "../../components/TopHeader.vue";
import MdNavBar from "../../components/MdNavBar.vue";

export default {
  data() {
    return {
      logoImg: logoImgSrc,
      whiteLogoImg: "",
      heroBgImg: "",
      footerBgImg: "",
      isLoading: false,
      message: "",
      journeyListData: [],
      images: [],
    };
  },
  components: {
    Loading,
    Footer,
    TopHeader,
    MdNavBar,
  },
  computed: {
    setBackgroundImage() {
      return {
        backgroundImage: `url(${this.heroBgImg})`,
      };
    },
  },
  methods: {
    async searchJourneyList() {
      this.isLoading = true;
      const journeyService = new JourneyService();
      await journeyService.getCompanyTripList();
      this.journeyListData = journeyService.companyTripListData.value;
      this.message = journeyService.message.value;

      this.getImageList();

      this.isLoading = false;
    },
    getImageList() {
      const imageList = [];

      // Loop through the journeyListData and collect image URLs
      this.journeyListData.forEach((item) => {
        if (item.vehicle && item.vehicle.images) {
          item.vehicle.images.forEach((image) => {
            if (image.image) {
              imageList.push(image.image);
            }
          });
        }
      });

      this.images = imageList;

      return imageList;
    },
    goToHome() {
      window.location.href = "/";
    },
  },
  created() {
    this.searchJourneyList();
  },
};
</script>

<style scoped>
.images {
  padding: 60px 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.image-item {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  transition: all 0.9s ease-out;
}

.image-item:hover img {
  transform: scale(1.1) rotate(-2deg);
}

.header-section {
  position: relative;
  text-align: center;
  padding: 20px;
  height: 440px !important;
  background-image: url("@/assets/images/mumuk-hero.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  z-index: 1; /* Position the overlay behind the content */
}

.header-section * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

header {
  background: #f4f4f4;
  color: #000;
  padding: 0 20px 0 0;
  border-radius: 8px;
  margin: 50px 140px 0 140px;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-bar-item {
  font-weight: 500;
  cursor: pointer;
  font-size: medium;
  transition: color 400ms ease-in-out;
}

.nav-bar-item:hover {
  color: #22b247;
}

.top-content-right {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media query for large screens (3 items per row) */
@media (min-width: 1024px) {
  .image-item {
    flex: 1 1 calc(33.333% - 20px); /* 3 items per row */
  }
}

/* Media query for medium screens (2 items per row) */
@media (min-width: 768px) and (max-width: 1023px) {
  .image-item {
    flex: 1 1 calc(50% - 20px); /* 2 items per row */
  }
  .images {
    padding: 60px 60px;
  }
}

/* Media query for small screens (1 item per row) */
@media (max-width: 767px) {
  .image-item {
    flex: 1 1 100%; /* 1 item per row */
  }
  .images {
    padding: 20px 20px;
  }

  .top-content {
    display: block !important;
  }
  .header-section {
    height: 200px !important;
  }
}

@media (max-width: 365px) {
  .header-section {
    height: 270px !important;
  }
}
</style>
