import { createRouter, createWebHistory } from "vue-router";
import Landing from "../../presentation/pages/landingPage/LandingPage.vue";
import Journey from "../../presentation/pages/journeyPage/AvailableJourney.vue";
import SelectSeat from "../../presentation/pages/journeyPage/SelectSeat.vue";
import MakePayment from "../../presentation/pages/journeyPage/MakePayment.vue";
import Ticket from "../../presentation/pages/journeyPage/Ticket.vue";
import Gallery from "../../presentation/pages/galleryPage/GalleryPage.vue";
import NotFound from "../../presentation/pages/notFoundPage/NotFound.vue";

const router = createRouter({
  mode: "history", // Use 'history' mode to remove the hash from the URL
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // If there is a saved position (like in a case of back button), scroll to that position
    // if (savedPosition) {
    //   return savedPosition;
    // } else {
    // Otherwise scroll to the top of the page
    return { top: 0 };
    // }
  },
  routes: [
    {
      path: "/",
      name: "landing",
      component: Landing,
    },
    {
      path: "/journey",
      name: "journey",
      component: Journey,
    },
    {
      path: "/selectSeat",
      name: "selectSeat",
      component: SelectSeat,
    },
    {
      path: "/makePayment",
      name: "makePayment",
      component: MakePayment,
    },
    {
      path: "/ticket",
      name: "ticket",
      component: Ticket,
    },
    {
      path: "/gallery",
      name: "gallery",
      component: Gallery,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
    },
  ],
});

export default router;
