<template>
  <div class="nav__toggle"></div>
  <div class="not-found flex flex-col items-center justify-center h-[100vh]">
    <h1>404 - Page Not Found</h1>
    <p>
      The page you are looking for might have been removed, had its name
      changed, or is temporarily unavailable.
    </p>
    <router-link to="/">Go to Home</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
}

.not-found h1 {
  font-size: 3em;
  color: #333;
}

.not-found p {
  font-size: 1.2em;
  color: #666;
}

.not-found a {
  margin-top: 20px;
  border: solid 1px #22b247;
  color: #22b247;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 30px;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.not-found a:hover {
  background-color: #22b247;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1), 0px 0px 40px rgba(0, 0, 0, 0.1),
    0px 0px 50px rgba(0, 0, 0, 0.1);
}
</style>
