<template>
  <!-- BUS IMAGES Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{
              busDetails
                ? busDetails.vehicle.company +
                  " - " +
                  busDetails.vehicle.registration_number
                : "Bus name"
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="busDetails != null" class="modal-body image-grid">
          <img
            v-for="(image, index) in busDetails
              ? busDetails.vehicle.images
              : []"
            :key="index"
            :src="image.image"
            alt="Bus"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="primary-button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- BUS AMENITIES Modal -->
  <div
    class="modal fade"
    id="seatBusAmenitiesModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="seatBusAmenitiesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="seatBusAmenitiesModalLabel">
            {{
              busDetails
                ? busDetails.vehicle.company +
                  " - " +
                  busDetails.vehicle.registration_number +
                  " - AMENITIES"
                : "Bus name"
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="flex flex-col gap-3 mt-3">
            <div
              v-if="busDetails"
              v-for="(amenite, index) in busDetails.vehicle.amenities"
              :key="index"
            >
              <div
                class="flex flex-row gap-3 bg-white rounded-lg p-2 shadow-sm items-center"
              >
                <img
                  :src="amenite.icon"
                  alt=""
                  srcset=""
                  style="width: 50px; height: 50px"
                  class="bg-gray-200 p-2 rounded-md"
                  data-bs-toggle="modal"
                  data-bs-target="#seatBusAmenitiesModal"
                />
                <div class="flex flex-col">
                  <h3 class="font-bold text-xl text-theme">
                    {{ amenite.name }}
                  </h3>
                  <p class="text-gray-400 text-[12px]">
                    {{ amenite.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="primary-button" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- LOADER -->
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      loader="spinner"
      background-color="#000"
      color="#fff"
      :opacity="0.5"
      :width="60"
      :height="60"
      :is-full-page="true"
    />
  </div>

  <div class="page-wrapper">
    <!-- ********    TOP-HEADER SECTION    ******** -->
    <TopHeader />
    <!-- ********    END TOP-HEADER SECTION    ******** -->

    <!-- ********    HEADER SECTION    ******** -->
    <div class="nav__toggle hidden"></div>
    <div class="header-section">
      <div class="top-content">
        <div></div>
        <div class="top-content-right">
          <h1 class="h1">
            Choose Your
            <div class="highlight text-second">Seat</div>
          </h1>
          <p class="p">
            Pick your preferred seat for a comfortable journey. Select and
            travel with ease.
          </p>
        </div>
      </div>
      <div
        class="journey-date bg-white-opacity text-black text-sm flex flex-row justify-between rounded-lg"
      >
        <div class="flex flex-col items-start p-3">
          <div
            class="flex flex-row gap-2 text-white text-[10px] md:text-[16px]"
          >
            <div>{{ fromName }}</div>
            <i class="bi bi-arrow-right-short text-theme text-[20px]"></i>
            <div>{{ toName }}</div>
          </div>
          <div class="text-gray-400 text-[12px] md:text-[16px]">
            <div>{{ busDetails.trip_date }}</div>
          </div>
        </div>
        <div
          @click="goBack"
          class="date-button flex flex-row justify-center items-center cursor-pointer bg-theme text-[14px] md:text-[16px] rounded-r-lg gap-2 p-3"
        >
          <i class="bi bi-chevron-left"></i>
          <button>Back</button>
        </div>
      </div>
    </div>
    <!-- ********    END HEADER SECTION    ******** -->

    <!-- ********    BODY SECTION    ******** -->
    <section class="seat-layout-section grid grid-cols-12 gap-3">
      <div class="bus-info col-span-12 md:col-span-6 lg:col-span-4">
        <div class="available-bus-item">
          <img
            v-if="busDetails.vehicle.images.length !== 0"
            :src="busDetails.vehicle.images[0].image"
            alt="Bus"
            @click="selectedBus = bus"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          />
          <img
            v-else
            :src="busImageSrc"
            alt="Bus"
            @click="toast.info('This bus has no Images.')"
          />
          <div class="available-bus-info">
            <div
              class="route-details flex flex-row justify-between text-gray-400 text-[12px] w-full"
            >
              <div class="from">From</div>
              <div>{{ busDetails.remained_seats }} Seats remain</div>
              <div class="to">To</div>
            </div>
            <div
              class="route-details grid grid-cols-3 flex-row justify-between items-center text-gray-700 text-[10px] w-full py-2 gap-2"
            >
              <div class="from col-span-1">
                <div class="font-bold text-[12px]">
                  {{ busDetails.trip_route.start_point_code }}
                </div>
                <div>{{ busDetails.trip_route.start_point }}</div>
                <div>{{ twelveHourFormat(busDetails.departing_time) }}</div>
              </div>
              <div class="flex flex-col justify-center items-center col-span-1">
                <i
                  class="bi bi-truck-front-fill bg-theme text-white px-2 py-1 text-[14px] rounded-[50%]"
                ></i>
                <div>{{ busDetails.trip_date }}</div>
              </div>
              <div class="to col-span-1 text-right">
                <div class="font-bold text-[12px]">
                  {{ busDetails.trip_route.end_point_code }}
                </div>
                <div>{{ busDetails.trip_route.end_point }}</div>
                <div>{{ twelveHourFormat(busDetails.arrival_time) }}</div>
              </div>
            </div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
            <div
              class="services text-[12px] flex flex-row items-center justify-center gap-2"
            >
              <div>{{ busDetails.vehicle.fleet_type }}</div>
              <div
                v-for="(amenite, index) in busDetails.vehicle.amenities"
                :key="index"
              >
                <img
                  :src="amenite.icon"
                  alt=""
                  srcset=""
                  style="width: 16px; height: 16px; border-radius: 0"
                  @click="selectedBus = bus"
                  data-bs-toggle="modal"
                  data-bs-target="#seatBusAmenitiesModal"
                />
              </div>
            </div>
            <div class="w-full flex flex-row gap-1 justify-center my-2">
              <div>Fare:</div>
              <div class="text-second font-bold">
                Tsh {{ formatNumber(busDetails.fare) }}/=
              </div>
            </div>
          </div>
          <div class="bus-name flex flex-row items-center p-2 rounded-md">
            <div>{{ busDetails.vehicle.company }}</div>
            <div class="w-[0.7px] h-3 bg-gray-300 mx-2"></div>
            <div>{{ busDetails.vehicle.registration_number }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-8">
        <div class="font-bold text-sm">SELECT SEAT</div>
        <div class="text-sm text-gray-500 pb-3">
          Select by tapping on a desired seat
        </div>
        <div class="grid grid-cols-3 gap-3">
          <div v-if="!isLoading" class="col-span-3 lg:col-span-1">
            <div class="seats-container">
              <!-- ********     SEAT LAYOUT    ******** -->
              <div class="seats p-3 rounded-lg shadow-md w-full">
                <div
                  v-for="(row, rowIndex) in journeyData.seats"
                  :key="rowIndex"
                  class="seat-row"
                >
                  <div
                    v-for="seat in row"
                    :key="seat.id"
                    :class="[
                      'seat',
                      getSeatClass(seat),
                      {
                        'selected-seat': selectedSeats.includes(
                          seat.seat_number
                        ),
                      },
                    ]"
                    @click="
                      seat.is_active &&
                        seat.space_type == 'SEAT' &&
                        !seat.is_processing &&
                        toggleSeat(seat)
                    "
                  >
                    <span>{{ seat.seat_number }}</span>
                    <!-- <img :src="seatImageSrc" alt="" srcset="" /> -->
                    <div
                      v-if="seat.space_type !== 'GAP'"
                      class="seat-layout-left"
                    ></div>
                    <div
                      v-if="seat.space_type !== 'GAP'"
                      class="seat-layout-right"
                    ></div>
                    <div
                      v-if="seat.space_type !== 'GAP'"
                      class="seat-layout-bottom"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="seat-info col-span-3 lg:col-span-1 p-3 rounded-lg shadow-md w-full"
          >
            <div class="font-bold text-sm mb-2">SEAT SELECTION</div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
            <div
              class="flex flex-row gap-1 items-center text-[12px] text-gray-500 pb-3"
            >
              <div>Selected seat:</div>
              <div class="text-black text-[14px] font-bold">
                {{ selectedSeats.join(", ") }}
              </div>
            </div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
            <div
              class="flex flex-row items-center gap-3 mb-1 text-[11px] text-gray-500"
            >
              <div class="w-3 h-3 bg-[#e0e5e8] rounded-sm"></div>
              <div class="text-gray">Available</div>
            </div>
            <div
              class="flex flex-row items-center gap-3 mb-1 text-[11px] text-gray-500"
            >
              <div class="w-3 h-3 bg-theme rounded-sm"></div>
              <div class="text-gray">Selected</div>
            </div>
            <div
              class="flex flex-row items-center gap-3 mb-1 text-[11px] text-gray-500"
            >
              <div class="w-3 h-3 bg-[#2ba0ff] rounded-sm"></div>
              <div class="text-gray">Processing</div>
            </div>
            <div
              class="flex flex-row items-center gap-3 mb-3 text-[11px] text-gray-500"
            >
              <div class="w-3 h-3 bg-[#108647] rounded-sm"></div>
              <div class="text-gray">Not Available</div>
            </div>

            <div class="font-bold text-sm mb-2">PASSENGER DETAILS</div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
            <form
              class="user-details"
              ref="passengerDetailsForm"
              @submit.prevent="bookTicket"
            >
              <div v-for="(seat, index) in selectedSeats" :key="index">
                <div class="font-bold text-[12px] mb-2">SEAT: {{ seat }}</div>
                <div class="text-[12px] text-gray-500 pb-1">Name*</div>
                <input
                  class="p-2 mb-2"
                  type="text"
                  :id="'name-' + index"
                  :name="'name-' + index"
                  v-model="passengerDetails[index].name"
                  placeholder="Full Name"
                  required
                />
                <div class="text-[12px] text-gray-500 pb-1">Phone Number*</div>
                <input
                  class="p-2 mb-2"
                  type="tel"
                  :id="'phone-number-' + index"
                  :name="'phone-number-' + index"
                  v-model="passengerDetails[index].phone_number"
                  placeholder="07********"
                  maxlength="10"
                  minlength="10"
                  required
                />
                <div class="text-[12px] text-gray-500 pb-1">Email</div>
                <input
                  class="p-2 mb-3"
                  type="email"
                  :id="'email-' + index"
                  :name="'email-' + index"
                  v-model="passengerDetails[index].email"
                  placeholder="abc@gmail.com"
                />
                <div class="flex w-full justify-center mb-3">
                  <CustomSwitch
                    :labels="['Male', 'Female']"
                    :value="passengerDetails[index].gender === 'Male'"
                    :onToggle="() => handleSwitchToggle(index, 'gender')"
                  />
                </div>
                <div
                  v-if="!isLoading && age_groups.length !== 0"
                  class="flex w-full justify-center mb-2"
                >
                  <CustomSwitch
                    :labels="this.age_groups.map((group) => group.name)"
                    :value="passengerDetails[index].age_group_id === 1"
                    :onToggle="() => handleSwitchToggle(index, 'age_group_id')"
                  />
                </div>
                <div v-if="passengerDetails[index].age_group_id === 1">
                  <div class="text-[12px] text-gray-500 pb-1">ID Type*</div>
                  <multiselect
                    class="containerActive mb-2"
                    v-model="passengerDetails[index].identification_type_id"
                    :options="
                      id_types.map((id_type) => ({
                        value: id_type.id,
                        label: id_type.name,
                      }))
                    "
                    :searchable="false"
                    :required="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Select ID Type"
                  ></multiselect>
                  <div class="text-[12px] text-gray-500 pb-1">ID Number*</div>
                  <input
                    class="p-2 mb-2"
                    type="text"
                    :id="'id-number-' + index"
                    :name="'id-number-' + index"
                    v-model="passengerDetails[index].id_number"
                    placeholder="ID Number"
                    required
                  />
                </div>
                <div class="text-[12px] text-gray-500 pb-1">
                  Pickup Station*
                </div>
                <multiselect
                  class="containerActive mb-2"
                  v-model="passengerDetails[index].pick_point_id"
                  :options="
                    pickupStations.map((station) => ({
                      value: station.id,
                      label: station.name,
                    }))
                  "
                  :searchable="false"
                  :required="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Select Station"
                ></multiselect>
                <div class="text-[12px] text-gray-500 pb-1">
                  Destination Station*
                </div>
                <multiselect
                  class="containerActive"
                  v-model="passengerDetails[index].drop_point_id"
                  :options="
                    dropOffStations.map((station) => ({
                      value: station.id,
                      label: station.name,
                    }))
                  "
                  :searchable="false"
                  :required="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Select Station"
                ></multiselect>
                <div
                  v-if="index !== selectedSeats.length - 1"
                  class="w-full h-[0.7px] bg-gray-300 mb-3"
                ></div>
              </div>
            </form>
          </div>
          <div
            class="ticket-info col-span-3 lg:col-span-1 p-3 rounded-lg shadow-md w-full min-h-auto"
          >
            <div class="font-bold text-sm mb-2">TICKET SUMMARY</div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">From:</div>
              <div class="col-span-5">
                {{ busDetails.trip_route.start_point }}
              </div>
            </div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">To:</div>
              <div class="col-span-5">
                {{ busDetails.trip_route.end_point }}
              </div>
            </div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">Date:</div>
              <div class="col-span-5">{{ busDetails.trip_date }}</div>
            </div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">Reporting:</div>
              <div class="col-span-5">
                {{ twelveHourFormat(busDetails.reporting_time) }}
              </div>
            </div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">Departing:</div>
              <div class="col-span-5">
                {{ twelveHourFormat(busDetails.departing_time) }}
              </div>
            </div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">Arrival:</div>
              <div class="col-span-5">
                {{
                  busDetails.arrival +
                  " " +
                  twelveHourFormat(busDetails.arrival_time)
                }}
              </div>
            </div>
            <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
              <div class="col-span-2">Fare:</div>
              <div class="col-span-5 font-bold">
                Tsh {{ formatNumber(busDetails.fare) }}/=
              </div>
            </div>
            <div class="w-full h-[0.7px] bg-gray-300 mb-3"></div>
            <div class="w-full flex justify-center">
              <button @click="submitForm" class="primary-button text-[12px]">
                Reserve Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ********    END BODY SECTION    ******** -->

    <!-- ********    FOOTER SECTION    ******** -->
    <section class="footer-section"><Footer /></section>
    <!-- ********    END FOOTER SECTION    ******** -->
  </div>
</template>

<script>
import bus from "@/assets/images/bus.jpg";
import Multiselect from "@vueform/multiselect";
import Footer from "../../components/Footer.vue";
import TopHeader from "../../components/TopHeader.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";
import { useToast } from "vue-toastification";

import JourneyService from "../../../core/services/journeyService.js";
import sessionManager from "../../../core/utils/sessionManager.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  data() {
    return {
      busImageSrc: bus,
      busDetails: {},
      isSwitched: true,
      idType: "",
      fromName: "",
      toName: "",
      tripDate: "",
      journeyData: {},
      journeyId: 0,
      isLoading: false,
      selectedSeats: [],
      passengerDetails: [],
      pickupStations: [],
      dropOffStations: [],
      id_types: [],
      age_groups: [],
      selectedBus: null,
    };
  },
  components: {
    Loading,
    Footer,
    CustomSwitch,
    Multiselect,
    TopHeader,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async bookTicket() {
      if (this.selectedSeats.length <= 0) {
        return;
      }

      this.isLoading = true;
      const journeyService = new JourneyService();

      this.passengerDetails.forEach((passenger) => {
        // Set parent_number
        passenger.parent_number = passenger.phone_number;

        // Check and set default for pick_point_id
        if (!passenger.pick_point_id) {
          passenger.pick_point_id = this.pickupStations[0].id;
        }

        // Check and set default for drop_point_id
        if (!passenger.drop_point_id) {
          passenger.drop_point_id =
            this.dropOffStations[this.dropOffStations.length - 1].id;
        }
      });

      await journeyService.bookTicket({ bookings: this.passengerDetails });
      this.isLoading = false;
      if (journeyService.success.value) {
        this.toast.success(journeyService.message.value);
        sessionManager.storeBookingDetails(
          journeyService.bookingListData.value
        );

        this.$router.push({
          path: "/makePayment",
        });
      } else {
        this.toast.error(journeyService.message.value);
      }
    },
    async fetchData() {
      this.isLoading = true;

      try {
        await Promise.all([
          this.fetchIdTypes(),
          this.fetchAgeGroups(),
          this.fetchJourney(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchIdTypes() {
      const journeyService = new JourneyService();
      const id_types = sessionManager.getIdTypes();
      if (id_types != null && id_types.length > 0) {
        this.id_types = id_types;
      } else {
        await journeyService.getIdTypes();
        this.id_types = journeyService.idTypeListData.value;
        sessionManager.storeIdTypes(this.id_types);
      }
    },

    async fetchAgeGroups() {
      const journeyService = new JourneyService();
      const age_groups = sessionManager.getAgeGroups();
      if (age_groups != null && age_groups.length > 0) {
        this.age_groups = age_groups;
      } else {
        await journeyService.getAgeGroups();
        this.age_groups = journeyService.ageGroupListData.value;
        sessionManager.storeAgeGroups(this.age_groups);
      }
    },

    async fetchJourney() {
      const journeyService = new JourneyService();
      this.journeyId = parseInt(this.$route.query.id, 10);

      await journeyService.getJourney(this.journeyId);
      this.journeyData = journeyService.journeyData.value;

      if (this.journeyData.seats.length === 0) {
        this.journeyData.seats = this.generateSeatLayout(
          this.journeyData.oldSeats,
          this.journeyData.layout_type
        );
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async submitForm() {
      const form = this.$refs.passengerDetailsForm;
      if (form.checkValidity()) {
        if (await this.checkSeatAvailability()) {
          this.bookTicket();
        } else {
          this.fetchData();
        }
      } else {
        form.reportValidity();
      }
    },

    handleSwitchToggle(index, key) {
      if (key == "gender") {
        if (this.passengerDetails[index].gender == "Male") {
          this.passengerDetails[index].gender = "Female";
        } else {
          this.passengerDetails[index].gender = "Male";
        }
      } else {
        if (this.passengerDetails[index].age_group_id === 1) {
          this.passengerDetails[index].age_group_id = 2;
        } else {
          this.passengerDetails[index].age_group_id = 1;
        }
      }
    },

    toggleSeat(seat) {
      const seatIndex = this.selectedSeats.indexOf(seat.seat_number);

      if (seatIndex !== -1) {
        this.selectedSeats.splice(seatIndex, 1);

        this.passengerDetails.splice(seatIndex, 1);
      } else {
        this.selectedSeats.push(seat.seat_number);
        this.passengerDetails.push({
          seat_number: seat.seat_number,
          seat_type: seat.seat_type,
          parent_number: "",
          name: "",
          phone_number: "",
          journey_type_id: 1,
          journey_id: this.journeyId,
          age_group_id: 1,
          identification_type_id: 6,
          gender: "Male",
          id_number: "",
          pick_point_id: this.pickupStations[0].id,
          drop_point_id:
            this.dropOffStations[this.dropOffStations.length - 1].id,
          fare: this.busDetails.fare,
          email: null,
        });
      }
    },

    getSeatClass(seat) {
      if (seat.space_type === "GAP") {
        return "seat-gap";
      } else if (seat.space_type === "DOOR") {
        return "seat-door";
      } else if (seat.space_type === "DRIVER") {
        return "seat-driver";
      } else if (seat.space_type === "TOILET") {
        return "seat-toilet";
      }
      if (seat.is_reserved) {
        return "seat-reserved";
      }
      if (seat.is_processing) {
        return "seat-processing";
      }
      if (seat.is_active) {
        return "seat-active";
      }
      return "seat";
    },

    generateSeatLayout(oldSeats, layoutType) {
      const { left_side, right_side, front_seat_available, is_last_full_seat } =
        layoutType;
      const totalSeats = oldSeats.length;
      let seatIndex = 0;
      const layout = [];

      // Create the front seat if available
      if (front_seat_available) {
        const row = [];
        row.push({
          seat_number: oldSeats[seatIndex].number,
          space_type: "SEAT",
          seat_type: "SEAT",
          is_active: oldSeats[seatIndex].is_active,
          is_reserved: oldSeats[seatIndex].is_reserved,
          is_processing: oldSeats[seatIndex].is_processing,
          price: oldSeats[seatIndex].price,
        });
        seatIndex++;

        // FRONT SEATS GAPS
        for (let i = 0; i < left_side + right_side; i++) {
          if (seatIndex < totalSeats) {
            row.push({
              seat_number: "",
              space_type: "GAP",
              seat_type: "GAP",
              is_active: false,
              is_reserved: false,
              is_processing: false,
              price: false,
            });
            seatIndex++;
          }
        }
        layout.push(row);
      }

      while (seatIndex < totalSeats) {
        const row = [];

        // Left side seats
        for (let i = 0; i < left_side; i++) {
          if (seatIndex < totalSeats) {
            row.push({
              seat_number: oldSeats[seatIndex].number,
              space_type: "SEAT",
              seat_type: "SEAT",
              is_active: oldSeats[seatIndex].is_active,
              is_reserved: oldSeats[seatIndex].is_reserved,
              is_processing: oldSeats[seatIndex].is_processing,
              price: oldSeats[seatIndex].price,
            });
            seatIndex++;
          }
        }

        // Center gap or seat for the last row
        if (seatIndex < totalSeats) {
          if (
            is_last_full_seat &&
            seatIndex >= totalSeats - left_side - right_side - 1
          ) {
            row.push({
              seat_number: oldSeats[seatIndex].number,
              space_type: "SEAT",
              seat_type: "SEAT",
              is_active: oldSeats[seatIndex].is_active,
              is_reserved: oldSeats[seatIndex].is_reserved,
              is_processing: oldSeats[seatIndex].is_processing,
              price: oldSeats[seatIndex].price,
            });
            seatIndex++;
          } else {
            row.push({
              seat_number: "",
              space_type: "GAP",
              seat_type: "GAP",
              is_active: false,
              is_reserved: false,
              is_processing: false,
              price: 0,
            });
          }
        }

        // Right side seats
        for (let i = 0; i < right_side; i++) {
          if (seatIndex < totalSeats) {
            row.push({
              seat_number: oldSeats[seatIndex].number,
              space_type: "SEAT",
              seat_type: "SEAT",
              is_active: oldSeats[seatIndex].is_active,
              is_reserved: oldSeats[seatIndex].is_reserved,
              is_processing: oldSeats[seatIndex].is_processing,
              price: oldSeats[seatIndex].price,
            });
            seatIndex++;
          }
        }

        layout.push(row);
      }

      return layout;
    },

    getLocalData() {
      this.busDetails = sessionManager.getBusDetails();
      this.pickupStations =
        this.busDetails.trip_route.stations_data.pick_up_points;
      this.dropOffStations =
        this.busDetails.trip_route.stations_data.drop_off_points;
      this.stations = this.busDetails.trip_route.stations;
      if (this.pickupStations.length === 0) {
        this.pickupStations = this.stations;
      }
      if (this.dropOffStations.length === 0) {
        this.dropOffStations = this.stations;
      }
      this.fromName = sessionManager.fromName.value;
      this.toName = sessionManager.toName.value;
      this.tripDate = sessionManager.date.value;
    },

    async checkSeatAvailability() {
      if (this.selectedSeats.length <= 0) {
        return;
      }
      const data = {
        journey_id: this.journeyId,
        seats: this.selectedSeats,
      };
      const journeyService = new JourneyService();
      await journeyService.checkSeatAvailability(data);

      if (!journeyService.success.value) {
        this.toast.error(journeyService.message.value);
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    selectedSeats(newSeats) {
      // Add new entries if there are more seats than passenger details
      if (newSeats.length > this.passengerDetails.length) {
        const newEntries = newSeats
          .slice(this.passengerDetails.length)
          .map((seat) => ({
            seat_number: seat.seat_number, // Use seat.seat_number
            seat_type:
              this.journeyData.seats.find(
                (s) => s.seat_number === seat.seat_number
              )?.seat_type || "", // Find seat type based on seat_number
            name: "",
            phone_number: "",
            parent_number: "",
            journey_type_id: 1,
            journey_id: this.journeyId,
            age_group_id: 1,
            identification_type_id: 6,
            gender: "male",
            id_number: "",
            pick_point_id: this.pickupStations[0].id,
            drop_point_id:
              this.dropOffStations[this.dropOffStations.length - 1].id,
            fare: this.busDetails.fare,
            email: null,
          }));

        this.passengerDetails.push(...newEntries);
      }
      // Remove entries if there are fewer seats than passenger details
      else if (newSeats.length < this.passengerDetails.length) {
        this.passengerDetails.length = newSeats.length;
      }
    },
  },
  created() {
    this.getLocalData();
    this.fetchData();
  },
};
</script>

<style scoped>
.bg-white-opacity {
  background-color: rgba(0, 0, 0, 0.5);
}

.page-wrapper {
  overflow-x: hidden;
}

.header-section {
  position: relative;
  text-align: center;
  padding: 20px;
  height: 400px;
  background-image: url("@/assets/images/mumuk-hero.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  z-index: 1; /* Position the overlay behind the content */
}

.header-section * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.journey-date {
  margin-left: 260px;
  margin-right: 260px;
}

.seat-layout-section {
  margin: 60px 160px;
}

.seats-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seat-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.seat {
  color: #333;
  font-size: 12px;
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 2px 2px 8px 8px;
  background-color: #f5f5f5;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.seat-active {
  background-color: #e0e5e8;
  cursor: pointer;
}

.seat-reserved {
  background-color: #108647;
  cursor: not-allowed;
  color: white;
}

.seat-processing {
  background-color: #2ba0ff;
  cursor: not-allowed;
  color: white;
}

.seat-active:hover {
  transform: scale(0.9);
  border: none;
  background-color: rgba(255, 212, 40, 0.5);
  color: white;
}

.selected-seat {
  background-color: #d80322;
  color: white;
}

.selected-seat:hover {
  background-color: rgba(255, 212, 40, 0.5);
}

.seat-gap {
  background-color: transparent;
  border: none;
}

.seat-door {
  background-color: transparent;
  border: none;
}

.seat-door::before {
  content: "Door";
  font-weight: bold;
  font-size: 9px;
}

.seat-driver {
  width: 30px;
  height: 30px;
  border: 3px solid #c9c5c5;
  border-radius: 50%;
  background-color: transparent;
}

.seat-driver::before {
  content: "Driver";
  font-weight: bold;
  font-size: 9px;
}

.seat-toilet {
  background-color: #66d2669c;
  border: none;
  border-radius: 0 6px 6px 0;
  padding: 9px;
  font-weight: bold;
}

.seat-toilet::before {
  content: "Toilet";
  font-weight: bold;
  font-size: 10px;
}

.available-bus-item {
  width: 100%;
  position: relative;
  background-color: white;
  border: #e2dedc7e 0.1px solid;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bus-name {
  font-size: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.611);
  color: white;
  font-weight: 500;
}

.available-bus-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
}

.available-bus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
}

.modal-body {
  display: flex;
  justify-content: center; /* Center the grid horizontally */
  align-items: center; /* Center the grid vertically */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Space between grid items */
  max-width: 100%; /* Ensure grid doesn't overflow */
  justify-items: center; /* Center items in the grid */
  align-items: center; /* Center items vertically in the grid */
}

.image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.image-grid img:hover {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

@media (max-width: 1184px) {
  .seat-layout-section {
    margin: 60px 80px;
  }
  .journey-date {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 820px) {
  .seat-layout-section {
    margin: 40px 60px;
  }
}

@media (max-width: 768px) {
  .journey-date {
    margin-left: 0px;
    margin-right: 0px;
  }
  .seat-layout-section {
    margin: 20px 20px;
  }
  .header-section {
    height: 500px !important;
  }
}

@media (max-width: 576px) {
  .header-section {
    height: 500px !important;
  }
}
</style>
